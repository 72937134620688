<template>
  <div class="photos-container">
      <div class="flex-container">
          <div
              class="flex-item"
              :class="{ 'selected': selectedIndex == 'theme' }"
              @click="selectItem('theme')">
              场景
              </div>
          <div
              class="flex-item"
              :class="{ 'selected': selectedIndex == 'custom' }"
              @click="selectItem('custom')">
              Custom
              </div>
          <div
              class="flex-item"
              :class="{ 'selected': selectedIndex == 'example' }"
              @click="selectItem('example')">
              Example
          </div>
        </div>
        <div class="themes_container">
            <div class="themes">
              <div class="theme" v-for="theme in themeList" :key="theme.id" @click="selectTheme(theme.id)" :class="{ 'selected': selectedTheme === theme.id }">
                  <img :src="theme.src" alt="照片">
                  <div class="text-overlay">
                      {{ theme.text }}
                  </div>
              </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "navigator-assets",
  data() {
      return{
        selectedIndex: 'theme',
        selectedTheme: 1,
        themeList:[
              { id: 1 , src: require('../../assets/images/samples/theme.jpg'),theme:"home_appliances",text:"小家电"},
              { id: 2 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"flowers", text:"鲜花"},
              { id: 3 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"test", text:"其它"},
              { id: 4 , src: require('../../assets/images/samples/theme.jpg'),theme:"test", text:"其它"},
              { id: 5 , src: require('../../assets/images/samples/theme.jpg'),theme:"test", text:"其它"},
              { id: 6 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"test", text:"其它"},
              { id: 7 , src: require('../../assets/images/samples/theme.jpg'),theme:"test", text:"其它"},
              { id: 8 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"test", text:"其它"},
              { id: 9 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"test", text:"其它"},
              { id: 10 , src: require('../../assets/images/samples/theme.jpg'),theme:"test", text:"其它"},
              { id: 11 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"test", text:"其它"},
              { id: 12 , src: require('../../assets/images/samples/theme.jpg'),theme:"test", text:"其它"},
              { id: 13 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"test", text:"其它"},
              { id: 14 , src: require('../../assets/images/samples/theme.jpg'),theme:"test", text:"其它"},
              { id: 15 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"test", text:"其它"},
              { id: 16 , src: require('../../assets/images/samples/theme.jpg'),theme:"test", text:"其它"},
              { id: 17 , src: require('../../assets/images/samples/theme.jpg'),theme:"test", text:"其它"},
              { id: 18 , src: require('../../assets/images/samples/theme_bg.jpg'),theme:"test", text:"其它"},
          ],
      }
  },
  mounted(){
    this.storageTheme()
  },
  methods: {
    // 风格
    selectItem(index) {
        this.selectedIndex = index;
    },
    // 选择主题
    selectTheme(theme_id) {
        this.selectedTheme = theme_id;
        this.storageTheme()
    },
    // 存储到vuex可以在其它页面内使用
    storageTheme(){
      // 获取默认选中的theme
      const theme = this.themeList.find(item => item.id === this.selectedTheme);
      // 保存在vuex中
      this.$store.commit("setSelectTheme", theme)
    }
  }
};
</script>
<style  scoped>
.photos-container {
  display:flex;
  flex-direction: column;
  width: 83%;
  max-height: 100%; /* 设置容器的最大高度 */
}
.flex-container {
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
}

.flex-item {
  width: 25%;
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  font-weight: bolder;
  text-align: center;
  cursor: pointer; /* 添加光标指针，表明元素可点击 */
  transition: background-color 0.3s ease; /* 添加过渡效果 */ 
}
.flex-item.selected {
  background-color: rgb(242, 191, 25);;
}


.themes_container{
  height: 100%;
  width: 100%;
  margin-top: 10px;
  background-color: rgb(246, 248, 250); 
  border: 1px solid #ccc;
  overflow-y: auto;
}
.themes {
    margin-top: 10px;
    display: grid; 
    grid-template-columns: repeat(2, 1fr); 
    gap: 5px;
    width: 100%; 
    padding: 5px; 
    box-sizing: border-box;
}
.theme {
    height: 100%;
    position: relative;
    box-sizing: border-box;
}
.theme img{
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
}
/* 照片上的文字 */
.text-overlay {
  position: absolute;
  bottom: 5px; /* 距离图片底部的距离 */
  left: 5px; /* 距离图片左侧的距离 */
  color: white; /* 文字颜色 */
  border-radius: 5px; /* 圆角 */
  font-size: 14px; /* 文字大小 */
  font-weight: bolder;
}
.theme.selected {
  border: 3px solid #f6de60;
}
/* 鼠标放上浅灰色显示 */
.theme:hover::after {
    cursor: pointer;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 100%;
    z-index: 500;
}

</style>