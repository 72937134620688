<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<!--vue 可拖拽可缩放 vue-draggable-resizable 组件常用总结:
https://www.cnblogs.com/wangweizhang/p/11241788.html
-->

<template>
    <div>
        <TopNavigator ref="child"></TopNavigator>
        <div style="height: 93.5vh;">
            <div class="generate-page" >
                <!--左侧导航栏-->
                <div class="generate-page-left">
                    <div class="menu-wrapper">
                        <div
                            class="menu-item"
                            @click="selectMenuItem('create')"
                        >
                            <div :class="{ 'selected': selectedItem === 'create' }">
                                <el-icon class="menu-icon-style" :class="{'active-menu-color':selectedItem === 'create'}"><Picture /></el-icon>
                                <span :class="{'active-menu-color':selectedItem === 'create'}">创建</span>
                            </div>
                        </div>
                        <div
                            class="menu-item"
                            @click="selectMenuItem('assets')"
                        >
                            <div :class="{ 'selected': selectedItem === 'assets' }">
                                <el-icon class="menu-icon-style" :class="{'active-menu-color':selectedItem === 'assets'}"><Goods /></el-icon>
                                <span :class="{'active-menu-color':selectedItem === 'assets'}">物品</span>
                            </div>   
                        </div>
                        <div
                            class="menu-item"
                            @click="selectMenuItem('layers')"
                        >
                             <div :class="{ 'selected': selectedItem === 'layers' }">
                                <el-icon class="menu-icon-style" :class="{'active-menu-color':selectedItem === 'layers'}"><Coin /></el-icon>
                                <span :class="{'active-menu-color':selectedItem === 'layers'}">图层</span>
                             </div>
                        </div>
                        <div
                            class="menu-item"
                            @click="selectMenuItem('settings')"
                        >
                            <div  :class="{ 'selected': selectedItem === 'settings' }">
                                <el-icon class="menu-icon-style" :class="{'active-menu-color':selectedItem === 'settings'}"><Setting /></el-icon>
                                <span :class="{'active-menu-color':selectedItem === 'settings'}">设置</span>
                            </div>
                        </div>
                    </div>
                    <el-divider border-style="double" direction="vertical" style="height: 100%;margin: 0px 5px 0px 5px"/>
                    <router-view></router-view>
                    <!-- 分界线 -->
                    <div style="width: 1px;height: 100%;background-color: #e9e9e9;margin-left: 5px;"></div>
                </div>
                <!--主展示区-->
                <div class="generate-page-right">
                    <div class="generate-top" @wheel.prevent="onZoom">
                        <!--主展示区 left-->
                        <div class="generate-top-left"></div>
                        <!-- 透明背景区域 -->
                        <div class="generate-top-center">
                            <div
                                id="container"
                                ref="container"
                                
                                :style="{
                                    transform: 'scale(' + containerScale + ')',
                                    transformOrigin: 'center center'
                                }">
                                <div
                                    @mousedown="startDrag"
                                    @click="selectAssetClick('asset')"
                                    ref="asset"
                                    id="asset"
                                    :style="{
                                        left: assetPosition.left + 'px',
                                        top: assetPosition.top + 'px',
                                        width: assetSize.width + 'px',
                                        height: assetSize.height + 'px',
                                        position: 'relative',
                                        display:'flex',
                                    }">
                                    <div class="dian top-left" @mousedown="startResize($event, 'top-left')"></div>
                                    <div class="dian top-right" @mousedown="startResize($event, 'top-right')"></div>
                                    <div class="dian bottom-left" @mousedown="startResize($event, 'bottom-left')"></div>
                                    <div class="dian bottom-right" @mousedown="startResize($event, 'bottom-right')"></div>
                                    <img :src="transparent_background_url" :style="{ width: assetSize.width + 'px',height: 'auto'}">
                                </div>
                            </div>
                        </div>
                        <!--主展示区 right TODO：测试功能--> 
                        <div class="generate-top-right" @click="getAssetTransform"></div>
                    </div>
                    <!-- 底部 -->
                    <div class="generate-bottom">
                        <div class="generate-btn-container">
                            <div class="generate-btn-container-left">
                                <span style="font-weight: bold">最近</span>
                                <span style="color: #716e6e;" @click="goToRoutes('/pictures')">全部</span>
                            </div>
                             <div>
                                <el-button type="info" class="generate-button" @click="generatePictures" :disabled="isGenerating">
                                    {{ isGenerating ? '生成中...' : '生成' }}
                                </el-button>
                             </div>
                        </div>
                        <div class="scroll-container">
                            <div class="scroll-content">
                                <div v-for="(image) in AiImages" :key="image.id" class="box-shadow"  @mouseover="handleMouseOver" @click="goToPreview(image)">
                                    <img :src="image.url" alt="AI照片" class="centered-image">
                                    <el-icon v-if="showDelete" class="delete_icon icon" @click="deleteImage(image.picture_id)"><Delete/></el-icon>
                                    <el-icon v-if="showDownload" class="download_icon icon" @click="downLoadAiImage(image.picture_id,image.url)"><Download/></el-icon>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <!-- 自定义错误弹出框 -->
            <ErrorDialog v-model="dialogData.isModalVisible" @confirm="confirm">
                <div style="height: 200px; display: flex; flex-direction: column; justify-content: center; align-items: center; text-align: center;border-radius: 10px; padding: 20px;">
                    <p style="font-size: 16px; color: #333; margin-bottom: 15px;">{{ dialogData.errorMessage }}</p>
                    <p @click="goToRoutes('/pricing')" style="cursor: pointer; font-size: 16px; color: #555;">
                        请 
                        <!-- FREE用户显示充值会员 -->
                        <span style="color: blue; font-weight: bold;" v-if="membership_type=='FREE'">充值会员</span>
                        <!-- Basic会员用户显示升级会员 -->
                        <span style="color: blue; font-weight: bold;" v-else>升级会员</span>
                    </p>
                </div>
            </ErrorDialog>
        </div>
    </div>
</template>


<script>
import TopNavigator from "@/components/TopBar.vue";
import { ElButton ,ElMessageBox,ElMessage,ElLoading,ElDivider} from "element-plus";
import { deletePicture , createPictures,getPictures} from "@/api";
import { Delete,Download,Picture,Goods,Setting,Coin} from "@element-plus/icons";
import { HttpCodes ,StatusCodes} from '@/common/const';
import ErrorDialog from './templates/ErrorDialog.vue';
import { setSelectedElementStyle, handleUndoText, handleUndoSticker } from "@/utils/commonFunctions";
export default {
    name: "generate-main",
    data(){
        return{
            dialogData:{
                errorMessage:"上传失败请稍后重试",
                isModalVisible:false,
                confirmEvent:"",
            },
            membership_type:null,
            AiImages:[],
            selectedItem: "create",
            showDelete:false,
            showDownload:false,
            generateData:{
                asset_id:"",
                theme:""
            },
            isGenerating:false,
            // generate位置相关数据
            // 变换状态
            transform: {
                scale_w:null,
                scale_h:null,
                x:null,
                y:null
            },
            // 1024 * 1024 比例
            percentage: null,
            // 是否正在拖动
            isDragging: false,
            // 鼠标与图片的水平偏移量
            offsetX: 0,
            // 鼠标与图片的垂直偏移量
            offsetY: 0,
            // 透明图位置信息
            assetPosition: {
                left: 0,
                top: 0,
            },
            // 透明图渲染大小
            assetSize: {
                width: null,
                height: null,
            },
            scale: 1, // 当前缩放比例
            // 历史移动位置
            history: [],
            undoIndex: -1,
            // 透明图分辨率
            assetInitData:{
                width:null,
                height:null
            },
            // generate文本
            generateTexts:[],
            // generate贴纸
            generateStickers:[],

            startX: 0, // 鼠标开始位置
            startY: 0,
            resizing: false, // 缩放状态
            anchorPoint: '', // 当前缩放的锚点
            aspectRatio:"",
            containerScale:1,

        }
    },
    components: {
        TopNavigator,
        ElButton,
        Delete,
        Download,
        ErrorDialog,
        Picture,
        Goods,
        Setting,
        Coin,
        ElDivider

    },
    computed:{
        // 准备生成AI图的商品（vuex获取）
        selectAssetUrl(){
            return this.$store.state.user.selectAsset.asset_url
        },
        transparent_background_url(){
            return this.$store.state.user.selectAsset.transparent_background_url
        },
        selectAssetName(){
            return this.$store.state.user.selectAsset.name || "请选择商品图"
        },
        selectAssetId(){
            return this.$store.state.user.selectAsset.id || ""
        },



    },
    mounted(){
        // 判断是否有选中的Asset，如果没有返回到个人主页
        if (Object.keys(this.$store.state.user.selectAsset).length === 0) {
            this.$router.push("/personal")
        }
        this.getPictureList()
        this.getAssetInitData();
        // 获取会员类型
        this.membership_type =  JSON.parse(localStorage.getItem("userInfo")).membership_type
        // 记录键盘ctrl + z事件
        window.addEventListener("keydown", this.handleUndo);
        this.getActivateItem()
    },
    methods: {
        getActivateItem(){
            const routeMap = {
                "/generate/settings": "settings",
                "/generate/assets": "assets",
                "/generate/create": "create",
                "/generate/layers": "layers",
                "/generate/sticker": "layers",
            };
            // 根据当前路由路径设置 selectedView
            this.selectedItem = routeMap[this.$route.path]
        },
        // 获取透明图初始化位置相关信息
        getAssetInitData() {
            // 照片加载出来记录照片信息
            const img = this.$refs.asset.querySelector("img");
            img.onload = () => {
                // 记录照片的分辨率
                this.assetInitData.width = img.naturalWidth
                this.assetInitData.height = img.naturalHeight
        
                // 获取container的高和宽
                const containerHight = this.$refs.container.getBoundingClientRect().height;
                const containerWidth = this.$refs.container.getBoundingClientRect().width;

                // 获取1024和屏幕容器比例
                const bl = 1024 / containerHight;
                this.percentage = bl;
                // 将照片缩放比例转换为在透明背景大小的相对比例
                this.transform.scale_w = this.transform.scale_w / bl
                this.transform.scale_h = this.transform.scale_h / bl

                // 初始占透明区域的1/2,计算宽和高最大缩放，不能超出透明区域
                const scale_width = (containerWidth / 2) / img.naturalWidth
                const scale_height =  (containerHight / 2) / img.naturalHeight

                // 初始化大小在视觉上占透明区域的1/2
                this.assetSize.width = img.naturalWidth * Math.min(scale_width,scale_height)
                this.assetSize.height = img.naturalHeight * Math.min(scale_width,scale_height)

                // 获取缩放比例
                this.transform.scale_w = this.assetSize.width / img.naturalWidth
                this.transform.scale_h = this.assetSize.height / img.naturalHeight

                // 初始居中显示
                const assetLeft = (containerWidth - this.assetSize.width) / 2
                const phoneTop = (containerHight - this.assetSize.height) / 2
                // 获取在容器中的相对大小等比例
                this.assetPosition.left = assetLeft
                this.assetPosition.top = phoneTop

                // 记录transform
                this.transform.x = this.assetPosition.left * this.percentage
                this.transform.y = this.assetPosition.top * this.percentage
                // 记录初始位置信息
                this.savePosition();
                this.aspectRatio = this.assetSize.height / this.assetSize.width

            };
        },
        // 保存当前位置和尺寸
        savePosition() {
            const state = {
                position: { ...this.assetPosition },
                size: { ...this.assetSize },
            };
            this.history.push(state);
            this.undoIndex = this.history.length - 1;
        },
        undoAsset(){
            if (this.undoIndex > 0) {
                this.undoIndex--;
                const lastState = this.history[this.undoIndex];

                // 恢复之前保存的位置和大小
                this.assetPosition = { ...lastState.position };
                this.assetSize = { ...lastState.size };
                // 恢复上次transform的信息
                this.transform.x = this.assetPosition.left * this.percentage
                this.transform.y = this.assetPosition.top * this.percentage
                this.transform.scale_w = this.assetSize.width / this.assetInitData.width
                this.transform.scale_h = this.assetSize.height / this.assetInitData.height
            }
        },
        // 处理 Ctrl + Z 撤销
        handleUndo(event) {
            // 无论大小写都适用
            if (event.ctrlKey && event.key.toLowerCase() === 'z') {
                // 获取商品图
                const container = document.getElementById("container")
                // 获取背景容器
                if (!container){
                    return
                }
                // 获取选中的元素
                const selectedElement = container.querySelector(".selected");
                if(selectedElement){
                    const elementUniqueId = selectedElement.id
                    if(elementUniqueId == "asset"){
                        this.undoAsset()
                    }
                    // 文本撤销
                    if(elementUniqueId.startsWith("text")){
                        handleUndoText(selectedElement)
                    }
                    // 贴纸撤销
                    if(elementUniqueId.startsWith("sticker")){
                        handleUndoSticker(selectedElement)
                    }
                }
                this.undoAsset()
            }
            // 监听删除事件
            if (event.key === "Delete") {
                const container = document.getElementById("container");
                if(!container){
                    return
                }
                // 查找被选中的文本或者贴纸
                const allElements = container.querySelectorAll(".sticker-container,.text-container");
                // 筛选出含有 selected 类的元素
                allElements.forEach(element => {
                    const deleteElement = element.querySelector(".selected")
                    if(deleteElement){
                        element.remove()
                    }
                })
            }

        },
        startDrag(event) {
            // 阻止默写浏览器默认行为【有些浏览器当选中照片的时候会触发照片的下载链接】
            event.preventDefault();
            this.isDragging = true;
            this.offsetX = event.clientX - this.assetPosition.left;
            this.offsetY = event.clientY - this.assetPosition.top;
            document.addEventListener("mousemove", this.onDrag);
            document.addEventListener("mouseup", this.endDrag);
        },
        // 拖拽
        onDrag(event) {
            if (this.isDragging) {
                let newLeft = event.clientX - this.offsetX;
                let newTop = event.clientY - this.offsetY;
        
                // 边界检查，防止超出容器
                const containerWidth = this.$refs.container.offsetWidth;
                const containerHeight = this.$refs.container.offsetHeight;
                const assetWidth = this.assetSize.width;
                const assetHeight = this.assetSize.height;
        
                newLeft = Math.max(0, Math.min(containerWidth - assetWidth, newLeft));
                newTop = Math.max(0, Math.min(containerHeight - assetHeight, newTop));

                this.transform.x = this.percentage * newLeft
                this.transform.y = this.percentage * newTop
        
                this.assetPosition = { left: newLeft, top: newTop };
            }
        },
        // 拖动结束
        endDrag() {
            if (this.isDragging) {
                // 结束拖动
                this.isDragging = false; 
                // 拖动结束后保存位置
                this.savePosition(); 
                document.removeEventListener("mousemove", this.onDrag);
                document.removeEventListener("mouseup", this.endDrag);
                document.addEventListener('mouseout', this.mouseout);
            }
        },
        mouseout() {
            document.removeEventListener('mousemove', this.move);
        },
        // 透明背景缩放
        onZoom(event) {
            // 缩放因子
            const zoomStep = 0.05;
            // 根据滚轮方向增加或减少缩放比例
            this.containerScale += event.deltaY < 0 ? zoomStep : -zoomStep;
            // 限制缩放比例范围 最大为1默认是最大，最小为0.3
            if (this.containerScale < 0.3) this.containerScale = 0.3;
            if (this.containerScale > 1) this.containerScale = 1;
        },
        // 删除AI图
        deleteImage(id) {
            event.stopPropagation() // 阻止事件冒泡
            ElMessageBox.confirm(
                '删除产品图之后，将无法恢复，是否确认删除？',
                '提示框',
                {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() =>{
                deletePicture(id)
                .then(response => {
                    if(response.status == HttpCodes.SUCCESS){
                        this.AiImages = this.AiImages.filter(item => item.picture_id !== id);
                        this.showMessage("删除成功","success")
                    }else{
                        this.showMessage(response.data.error,"warning")
                    }
                })
            }).catch(() => {
                this.showMessage("已取消删除","info")
            })
        },
        // 下载照片
        downLoadAiImage(id,url){
            event.stopPropagation() // 阻止事件冒泡
            if(id == ""){
                this.showMessage("请选择要下载照片","warning")
                return
            }
            const fileName = id + ".jpg"
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        // 左侧导航栏
        selectMenuItem(item) {
            this.selectedItem = item;
            this.$router.push("/generate/" + item)
        },
        handleMouseOver(){
            this.showDownload = true
            this.showDelete = true
        },
        showMessage(message, type) {
            ElMessage({
                message: message,
                type: type,
                plain: true,
            });
        },
        // 弹框确定时间
        confirm(){
            // AI图余额不足
            if(this.dialogData.confirmEvent == "1"){
                this.dialogData.isModalVisible = false
                this.$router.push("/pricing")
                return
            }
            // generate请求失败
            if(this.dialogData.confirmEvent == "2"){
                this.dialogData.isModalVisible = false
            }

        },
        // 生成AI图
        generatePictures() {
            this.getAssetTransform()
            this.getGenerateTextStyle()
            this.getGenerateStickers()
            this.isGenerating = true
            // 显示正在加载中
            const loading = ElLoading.service({
                lock: true,
                text: '正在生成中...',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            // 获取asset_id
            this.generateData.asset_id = this.$store.state.user.selectAsset.asset_id;
            // 获取选中的theme
            this.generateData.theme = this.$store.state.user.selectTheme.theme;
            // 设置超时时间30秒
            const timeout = 120000;
            const timeoutId = setTimeout(() => {
                loading.close();
                this.showMessage("请求超时请重试","info")
                this.transform.scale_w = this.transform.scale_w / this.percentage
                this.transform.scale_h = this.transform.scale_h / this.percentage
                this.generateTexts = []
            }, timeout);
            // 调用生成图片的 API
            createPictures({
                asset_id:this.generateData.asset_id,
                theme:this.generateData.theme,
                // 携带商品图的位置信息
                transform:this.transform,
                texts:this.generateTexts,
                stickers:this.generateStickers
            })
            // 生成成功
                .then(response => {
                    // 生成成功
                    if(response.status == HttpCodes.SUCCESS){
                        this.transform.scale_w = this.transform.scale_w / this.percentage
                        this.transform.scale_h = this.transform.scale_h / this.percentage
                        // 生成完成改为false可点击
                        this.isGenerating = false
                        clearTimeout(timeoutId); 
                        loading.close();
                        this.showMessage("生成成功","success")
                        // 更新picture
                        this.getPictureList()
                        // 请求失败或者超时清空临时存储文本和贴纸的列表
                        this.generateStickers = []
                        this.generateTexts = []
                        const userInfo = JSON.parse(localStorage.getItem("userInfo"))
                        const membership_type = userInfo.membership_type
                        // Pro不做处理
                        if (membership_type.endsWith("_PRO")){
                            return
                        }
                        // TODO:如果超过定时器60s,为完成则走不到更新credits，直接到catch里面，后端依旧继续生成
                        // Free和Basic减少额度
                        userInfo.credits = response.data.data.credits
                        localStorage.setItem("userInfo",JSON.stringify(userInfo))
                        return
                    }
                })
                // 请求失败或者超时
                .catch(error => {
                    // 请求失败或者超时清空临时存储文本和贴纸的列表
                    this.generateTexts = []
                    this.generateStickers = []
                    this.transform.scale_w = this.transform.scale_w / this.percentage
                    this.transform.scale_h = this.transform.scale_h / this.percentage
                    // 失败页改为false
                    this.isGenerating = false
                    // 清除超时定时器
                    clearTimeout(timeoutId);
                    loading.close();
                    // AI图数量不足
                    if(error.response.status == HttpCodes.BAD_REQUEST && error.response.data.code == StatusCodes.PRODUCT_EXCEED_PICTURES_MAXIMUM_ERR){
                        // 显示顶部提示AI图数量不足
                        this.$refs.child.show_top = true
                        // 显示弹窗提示
                        this.dialogData.errorMessage = "可生成AI图数量不足!"
                        this.dialogData.confirmEvent = "1"
                        this.dialogData.isModalVisible = true
                    }
                    // 请求失败错误
                    console.log("Generate picture error",error);
                });
        },
        // 获取所有文本
        getGenerateTextStyle(){
            const container = document.getElementById('container');
            const pElements = container.querySelectorAll('.text-container');
            pElements.forEach(element => {
                const pTag = element.querySelector('p');
                const textContainer = element.querySelector('.DT-text-container');
                const textStyle = {
                    // 字体样式
                    font_family: textContainer.style.fontFamily || "",
                    // 字体粗细
                    font_weight: textContainer.style.fontWeight || "",
                    // 排列方式【左对齐、右对齐、居中、两端对齐】
                    text_align: textContainer.style.textAlign || "",
                    // 是否有下划线或者删除线
                    text_decoration: textContainer.style.textDecoration || "",
                    // 字体大小
                    font_size: parseInt(textContainer.style.fontSize, 10) || 0,
                    // 行间距
                    line_height: parseInt(textContainer.style.lineHeight, 10) || 0,
                    // 字间距
                    letter_spacing: parseFloat(textContainer.style.letterSpacing) || 0,
                    // 颜色
                    color: textContainer.style.color || "",
                    // 背景色
                    background_color: textContainer.style.backgroundColor || "",
                    // 去除单位 'px'，并确保值为空时设置为 0
                    x: element.style.left ? parseFloat(element.style.left.replace("px", "")) : 0, // x轴距离
                    y: element.style.top ? parseFloat(element.style.top.replace("px", "")) : 0 ,// y轴距离
                    // 文本框的宽度和高度
                    width:parseFloat(element.getBoundingClientRect().width),
                    height:parseFloat(element.getBoundingClientRect().height),
                };
                // 传换为1024 * 1024比例 保留一位小数
                textStyle.letter_spacing = textStyle.letter_spacing * this.percentage.toFixed(1);
                textStyle.font_size = textStyle.font_size * this.percentage
                textStyle.x = textStyle.x * this.percentage
                textStyle.y = textStyle.y * this.percentage
                textStyle.width = textStyle.width * this.percentage
                textStyle.height = textStyle.height * this.percentage
                textStyle.text_content = pTag.textContent
                this.generateTexts.push(textStyle)
            })
        },
        // 获取generate的贴纸
        getGenerateStickers(){
            // 获取透明背景容器
            const container = document.getElementById('container');
            // 查询里面的所有贴纸容器
            const stickers = container.querySelectorAll('.sticker-container');
            // 遍历
            stickers.forEach(stickerContainer =>{
                const sticker = stickerContainer.querySelector("img")
                // 获取贴纸的分辨率实际大小
                const stcikerNaturalWidth = sticker.naturalWidth
                const stickerWidth = sticker.width
                // 计算出缩放比例 【等比缩放】
                const scale = stickerWidth / stcikerNaturalWidth * this.percentage
                // 祖闯贴纸信息
                const stickerInformations = {
                    url:sticker.src,
                    scale_w:scale,
                    scale_h:scale,
                    // x轴距离
                    // 如果stickerContainer的left为空字符串则没尽兴移动，位置为0，否则就取出left的值去掉单位 px
                    x: stickerContainer.style.left === "" ? 0 : parseFloat(stickerContainer.style.left.replace("px", "")),
                    // y轴距离
                    y: stickerContainer.style.top === "" ? 0 : parseFloat(stickerContainer.style.top.replace("px", ""))
                }
                stickerInformations.x = stickerInformations.x * this.percentage
                stickerInformations.y = stickerInformations.y * this.percentage
                this.generateStickers.push(stickerInformations)
            })
        },
        // 获取asset变换状态
        getAssetTransform(){
            const asset = document.getElementById("asset")
            this.transform.x = parseInt(asset.style.left) * this.percentage
            this.transform.y = parseInt(asset.style.top) * this.percentage
            const assetImg = asset.querySelector("img")
            this.transform.scale_w = this.assetSize.width / assetImg.naturalWidth * this.percentage
            this.transform.scale_h = this.transform.scale_w 
        },
        // 获取生成的AI图
        getPictureList(){
            getPictures().then(response => {
                if(response.status == HttpCodes.SUCCESS){
                    this.AiImages = response.data.data.pictures
                }
            }).catch(error => {
                console.log("Get pictures error",error)
            })
        },
        // 去预览
        goToPreview(picture){
            this.$store.commit("setSelectPicture", picture)
            this.$router.push("/picturepreview")
        },
        // 导航至其它页面
        goToRoutes(routePth){
            this.$router.push(routePth)
        },
        // 开始以某个顶点进行缩放
        startResize(event, point) {
            event.stopPropagation()
            // 记录鼠标初始位置和锚点
            this.startX = event.clientX;
            this.startY = event.clientY;
            this.anchorPoint = point;
            this.resizing = true;

            // 监听鼠标移动和松开事件
            document.addEventListener("mousemove", this.onResize);
            document.addEventListener("mouseup", this.stopResize);
        },
        // 缩放过程
        onResize(event) {
            event.stopPropagation()
            if (!this.resizing) return;
            // 计算鼠标移动距离
            const dx = event.clientX - this.startX;
            let containerHight = this.$refs.container.getBoundingClientRect().height;
            let containerWidth = this.$refs.container.getBoundingClientRect().width;
            // 根据锚点更新位置和尺寸
            switch (this.anchorPoint) {
                case "top-left":
                    if(dx < 0 && (this.assetSize.width >= containerWidth || this.assetSize.height >= containerHight)){
                        return
                    }
                    this.assetSize.width -= dx;
                    this.assetSize.height = this.assetSize.width * this.aspectRatio;
                    this.assetPosition.left += dx;
                    this.assetPosition.top += dx * this.aspectRatio;
                    break;
                case "top-right":
                    if(dx > 0 && (this.assetSize.width >= containerWidth || this.assetSize.height >= containerHight)){
                        return
                    }
                    this.assetSize.width += dx;
                    this.assetSize.height = this.assetSize.width * this.aspectRatio;
                    this.assetPosition.top -= dx * this.aspectRatio;
                    break;
                case "bottom-left":
                    if(dx < 0 && (this.assetSize.width >= containerWidth || this.assetSize.height >= containerHight)){
                        return
                    }
                    this.assetSize.width -= dx;
                    this.assetSize.height = this.assetSize.width * this.aspectRatio;
                    this.assetPosition.left += dx;
                    break;
                case "bottom-right":
                    if(dx > 0 && (this.assetSize.width >= containerWidth || this.assetSize.height >= containerHight)){
                        return
                    }
                    this.assetSize.width += dx;
                    this.assetSize.height = this.assetSize.width * this.aspectRatio;
                    break
            }
            // 更新初始鼠标位置
            this.startX = event.clientX;
            this.startY = event.clientY;
            // 记录此次的位置信息
            this.savePosition()
        },
        // 停止缩放
        stopResize() {
            this.resizing = false;
            document.removeEventListener("mousemove", this.onResize);
            document.removeEventListener("mouseup", this.stopResize);
        },
        selectAssetClick(elementId){
            setSelectedElementStyle(elementId,"container")
        }

            
    },
    // 当离开generate页面时，清除选中的Asset和Theme，避免直接输入路由进入generate页面，发起错误的http请求【为携带选择aseet_id和theme】
    // 也可以不进行判断可以直接进入generate，提示用户选择要generate的asset
    beforeRouteLeave(to, from, next) {
        // 离开generate页面是清除asset和theme
        // this.$store.commit("setSelectAsset", {})
        this.$store.commit("setSelectTheme",{})
        // 离开generate页面清空记录的历史位置信息
        this.$store.commit("setInintHistory",{})
        // 继续导航
        next();
    },
    beforeUnmount() {
        document.removeEventListener("keydown",this.handleUndo)
    },
};
</script>

<style scoped>

.generate-page{
    width: 100%;
    height: 100%;
    display: flex;
    user-select: none;
}

.generate-page-left{
    display: flex;
    /* width: 23%; */
    flex: 2.3;
    /* height: 835px; */
    /* margin-right: 10px; */
}
.generate-page-right{
    flex: 7.7;
    display: flex;
    flex-direction: column;
    align-items: first baseline;
}
.photos-container {
    width: 75%;
    max-height: 100%; /* 设置容器的最大高度 */
}
.flex-container {
    display: flex;
    margin-bottom: 10px;
    margin-top: 10px;
}

.flex-item {
    width: 25%;
    flex: 1;
    padding: 10px;
    border-radius: 5px;
    font-weight: bolder;
    text-align: center;
    cursor: pointer; /* 添加光标指针，表明元素可点击 */
    transition: background-color 0.3s ease; /* 添加过渡效果 */ 
}

.flex-item.selected {
    background-color: #da9b35; /* 点击选中后的背景颜色 */
    color: white; /* 文本颜色为白色 */
}

.photos {
    margin-left: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap; /* 允许照片在需要时换行 */
    gap: 10px; /* 可选项，定义照片之间的间隙 */
    max-height: 100%; /* 限制.photos高度 */
    overflow-y: scroll;
}

.photo {
    width: calc(50% - 15px); /* 计算每个照片占据的宽度，这里假设每行显示两个照片，可以根据需要调整 */
    height: auto; /* 保持图片纵横比 */
    position: relative;
}
/* 照片上的文字 */
.text-overlay {
    position: absolute;
    bottom: 5px; /* 距离图片底部的距离 */
    left: 5px; /* 距离图片左侧的距离 */
    color: white; /* 文字颜色 */
    padding: 5px 10px; /* 文字内边距 */
    border-radius: 5px; /* 圆角 */
    font-size: 14px; /* 文字大小 */
    font-weight: bolder;
}
.photo img{
    width: 100px;
    height: 100px;
}
.photo.selected {
    border: 4px solid #da9b35; /* 选中时的边框样式 */
}
.menu-wrapper {
    /* background-color: #f6f8fa; */
    width: 15%;
    /* height: 895px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30%;
}

.menu-item {
    flex: 1;
    width: 100%;
    /* text-align: center; */
    cursor: pointer;
}

.menu-item div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid transparent;
    height: 100%;
    /* padding: 10px 0; */
}
.menu-item div.selected{
    border-color: #409eff;
}
.active-menu-color{
    color: #409eff;
}
.menu-item span{
    margin-top: 5px;
    font-size: small;
    white-space: nowrap;
}


.menu-item:hover div{
    border-color: #409eff;
}
.menu-icon-style{
    height: 20px;
    width: 25px;
}


.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* generate移动区域 */
#container {
    margin: 10px 0px;
    max-width: 640px;
    max-height: 640px;
    /* 减去了margin */
    /* 固定高度让高宽比为1:1 */
    height: 98.4%;
    aspect-ratio: 1 / 1; /* 保持宽高比为1 */
    position: relative;
    overflow: hidden;
    display: flex;
    background-position: 0px 0px, 10px 10px;
    background-size: 20px 20px;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
}
  
#asset {
    /* 先定义边框颜色为透明 */
    border: 2px dashed transparent; /* 先定义边框，颜色为透明 */
    cursor: grab;
}
.btn-container {
    flex: 1;
    flex-direction: column;
}
.btn-container div:hover{
    cursor: pointer;
}
.btn-container div span{
    padding: 15px 40px;
    background-color: #409eff;
    color: white;
    border-radius: 10px;
    margin-left: 15px
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
}
#asset:hover{
    /* 鼠标放上去改变边框颜色 */
    border-color:rgb(191, 117, 33);
}
#asset.selected{
    border-color:rgb(191, 117, 33);
}

#asset.selected .dian{
    display: block;
}
#asset:hover .dian {
    display: block;

}
.dian {
    display: none;
    width: 10px; /* 圆点的大小 */
    height: 10px;
    background-color:  rgb(191, 117, 33); /* 圆点颜色 */
    border-radius: 50%; /* 使 div 变成圆形 */
    position: absolute;
}

.top-left { top: -5px; left: -5px; cursor: nwse-resize; }

.top-right { top: -5px;right: -5px; cursor: ne-resize; }

.bottom-left { bottom: -5px; left: -5px;cursor: nesw-resize; }

.bottom-right { bottom: -5px; right: -5px;cursor: nwse-resize; } 

.dian:hover {
    transform: scale(1.5);
}

/* generate移动区域 */

.generate-top{
    width: 100%;
    height: 73.5%;
    display: flex;
}
.generate-top-left{
    flex: 1;
}
.generate-top-center{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 2;
}
.generate-top-right{
    flex: 1;
}

.generate-bottom{
    width: 100%;
    background-color: #f6f8fa;
    display: flex;
    flex-direction: column;
    height: 26.5%;
}

.generate-btn-container{
    flex: 1;
    margin: 10px 0px 10px;
    display: flex; 
    align-items: center; 
    justify-content: space-between;
}
.generate-btn-container-left{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.generate-btn-container-left span{
    margin: 10px;
    font-size: large;
}
.generate-btn-container-left span:hover{
    cursor: pointer;
}

.generate-button {
    width: 150px; /* 调整按钮宽度 */
    height:50px; /* 调整按钮高度 */
    font-size: 20px; /* 调整按钮字体大小 */
}
.scroll-container {
    flex: 4;
    display: flex;
    width: 100%;
    padding-left: 10px; 
    box-sizing: border-box; 
    overflow-y: scroll;
    box-sizing: border-box;
    overflow-x: hidden;
}

.scroll-content {
    display: grid;
    /* 每行平均分成8份 */
    grid-template-columns: repeat(8, 1fr); 
    /* 元素之间的间距为5px */
    gap: 5px; 
     /* 不足8个时左对齐 */
    justify-content: start;
}

.box-shadow {
    /* 沾满1/8 */
    width: 100%;
    /* 高宽比1:1 */
    aspect-ratio: 1 / 1;
    position: relative;
    display: flex;
    justify-content: center;
}
.box-shadow img {
    display: block; 
    width: 100%; 
    height: 100%; 
}
.icon {
    opacity: 0.7;
    display: none;
    height: 15px;
    width: 15px;
    position: absolute;
    top: 3px;
    background-color: rgba(0, 0, 0, 0); /* 完全透明 */
    text-align: center;
    line-height: 20px;
    z-index: 1000;
    color: white;
}

.delete_icon {
    right: 25px;
}

.download_icon {
    right: 2px;
}

.box-shadow:hover .icon {
    display: block;
    cursor: pointer;
}

.box-shadow:hover::after {
    cursor: pointer;
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 100%;
    z-index: 500;
}


.delete-button {
    position: absolute; /* 绝对定位 */
    top: 10px; /* 距离顶部5px */
    right: 5px; /* 距离右侧5px */
    display: none; /* 默认隐藏 */
    background-color: red; /* 按钮背景色 */
    color: white; /* 按钮文字颜色 */
    border: none; /* 去掉边框 */
    border-radius: 50%; /* 圆形按钮 */
    width: 25px; /* 按钮宽度 */
    height: 25px; /* 按钮高度 */
    font-size: 12px; /* 按钮字体大小 */
    cursor: pointer; /* 鼠标悬停时显示手型 */
    align-items: center;
    justify-content: center;
}

.box-shadow:hover .delete-button {
    display: block; /* 鼠标悬停时显示删除按钮 */
}
</style>