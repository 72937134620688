<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
    <div>
        <div v-if="!showSearchAssetDialog && !showSearchPictureDialog">
          <div class="search-top">
              <div class="goBack-button" @click="goPersonal()">
                  <span style="font-size: 20px;">返回</span>
              </div>
          </div>
          <!-- Assets展示区 -->
          <div class="saerch-content">
              <div class="search-content-title">
                  <h1>物品库</h1>
              </div>
              <!-- 没有Assets展示空状态 -->
              <div style="margin: 0 auto;width: 1600px;display: flex;flex-wrap: wrap;" v-if="!showAssets">
                  <div class="el-empty-container">
                      <el-empty description="没有找到相关图" />
                  </div>
              </div>
              <!-- 有Assets展示Assets -->
              <div v-if="showAssets">
                <div style="margin: 0 auto;width: 1600px;display: flex;flex-wrap: wrap;">
                    <div style="display: flex;" v-for="asset in assets" :key="asset.id" class="image-wrapper" @click="goToAssetPreview(asset)">
                        <img :src="asset.url" width="300px" height="300px"/>
                        <el-icon style="top: 8px; right: 8px;" class="icon" :id="'drop_' + asset.id" @click="showPopup(asset.id)"><More/></el-icon>
                        <div class="modal" :id="'modal_' + asset.id" v-if="modalVisible === asset.id" @mouseleave="hidePopup(asset.id)">
                            <div class="modal-item" @click="download(asset.id,asset.url)">
                                <el-icon style="height: 20px;width: 20px"><Download/></el-icon>
                                <span class="modal-item-text">下载</span>
                            </div>
                            <div class="modal-item">
                                <el-icon style="height: 20px;width: 20px"><Male/></el-icon>
                                <span class="modal-item-text">查看详情</span>
                            </div>
                            <div class="modal-item" @click="deleteAsset(asset.id)">
                                <el-icon style="height: 20px;width: 20px"><Delete/></el-icon>
                                <span class="modal-item-text">删除</span>
                            </div>
                        </div>
                        <el-icon style="top: 8px; right: 43px;" class="icon" @click="download(asset.id,asset.url)"><Download/></el-icon>
                        <i style="right: 3%; top: 80%;">
                            <button class="preview-generate-button" @click="jumpToGenerate(asset)">创作</button>
                        </i>
                    </div>
                </div>
                <div class="page-style">
                  <ElConfigProvider :locale="zhCn">
                    <el-pagination 
                      layout="total,prev,pager,next,sizes,jumper"
                      :page-sizes="[10, 20, 30, 50, 100]"
                      :total="totalAssets" 
                      :default-page-size="AssetPageData.page_size"
                      @current-change="handleCurrentChange"
                      @size-change="handleSizeChange" 
                      />
                  </ElConfigProvider>
                </div>
              </div>
          </div>
          <!-- Pictures展示区 -->
          <div class="saerch-content">
              <div class="search-content-title">
                  <h1>生成图</h1>
              </div>
              <!-- 没有Pictures展示空状态 -->
              <div style="margin: 0 auto;; width: 1600px; display: flex; flex-wrap: wrap;" v-if="!showPictures">
                  <div class="el-empty-container">
                      <el-empty description="没有找到相关图" />
                  </div>
              </div>
              <!-- 有Pictures展示Pictures -->
              <div v-if="showPictures">
                <div style="margin: 0 auto;;width: 1600px;display: flex;flex-wrap: wrap;">
                    <div style="display: flex;" v-for="picture in pictures" :key="picture.id" class="image-wrapper" @click="goToPicturePreview(picture)">
                        <img :src="picture.url" width="300px" height="300px"/>
                        <el-icon style="top: 8px; right: 8px;" class="icon" :id="'drop_' + picture.id" @click="deleteAiImage(picture.id)"><Delete/></el-icon>
                        <el-icon style="top: 8px; right: 43px;" class="icon" @click="download(picture.id,picture.url)"><Download/></el-icon>
                    </div>
                </div>
                <div class="page-style">
                  <ElConfigProvider :locale="zhCn">
                    <el-pagination 
                      layout="total,prev,pager,next,sizes,jumper"
                      :page-sizes="[10, 20, 30, 50, 100]"
                      :total="totalPictures" 
                      :default-page-size="PicturePageData.page_size"
                      @current-change="handlePictureCurrentChange"
                      @size-change="handlePictureCurrentChange" 
                      />
                  </ElConfigProvider>
                </div>
              </div>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import zhCn from "element-plus/es/locale/lang/zh-cn";//！！
  import { Download ,More,Delete,Male } from "@element-plus/icons";
  import {ElMessage, ElMessageBox,ElEmpty,ElPagination,ElConfigProvider} from "element-plus";
  import { getAssets,deleteAsset,getPictures,deletePicture} from "@/api";
  import { HttpCodes } from "@/common/const";
  export default {
    name: "search-preview-layer",
    components: {
      Download,
      More,
      Delete,
      Male,
      ElEmpty,
      ElPagination,
      ElConfigProvider
    },
    data(){
      return {
        zhCn,
        showAssets:true,
        showPictures:true,
        modalVisible: false, // 初始时 modal 不可见
        // 测试数据
        assets:[],
        searchAssetIds:[],
        pictures:[],
        assetKeyword:"",
        AssetPageData:{
          page:1,
          // 固定一页八个
          page_size:10
        },
        totalAssets:"",
        PicturePageData:{
          page:1,
          page_size:10
        },
        totalPictures:""
      }
    },
    computed:{
          // 准备生成AI图的商品（vuex获取）
          selectAssetUrl(){
              return this.$store.state.user.selectAsset.url
          },
          selectAssetName(){
              return this.$store.state.user.selectAsset.name || "请选择商品图"
          },
          selectAssetId(){
              return this.$store.state.user.selectAsset.id || ""
          },
          showDialog(){
            return this.$store.state.user.showDialog 
          }
      },
    methods: {
      goPersonal(){
        this.$store.commit("setAssetKeyword", "")
        this.$router.push("/personal")
      },
      // 下载原商品提取图或者已经生成好的商品展示图
      download(id,url) {
        event.stopPropagation(); // 阻止事件冒泡
        const fileName = id + ".jpg"
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      deleteAsset(asset_id){
        event.stopPropagation(); // 阻止事件冒泡
        ElMessageBox.confirm(
          '删除之后，将无法恢复，是否确认删除？',
          'Warning',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).then(() => {
          deleteAsset(asset_id)
            .then(response => {
              if(response.status == HttpCodes.SUCCESS){
                ElMessage({
                  message:"删除成功",
                  type:"success"
                })
                // 获取新的前四个商品图
                this.getAssetsList()
              }else{
                // 删除错误的提示
                console.log(response)
              }
              })
              .catch(error => {
                console.error('Error deleting image:', error);
              });
          })
          .catch(() => {
            ElMessage({
              message:"已取消删除操作"
            })
        })
      },
      deleteAiImage(picture_id){
        event.stopPropagation(); // 阻止事件冒泡
        ElMessageBox.confirm(
          '删除之后，将无法恢复，是否确认删除？',
          'Warning',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).then(() => {
          deletePicture(picture_id)
            .then(response => {
              if(response.status == HttpCodes.SUCCESS){
                ElMessage({
                  message:"删除成功",
                  type:"success"
                })
                this.getAssetsList()
              }
              })
              .catch(error => {
                console.error('Error deleting image:', error);
              });
          })
          .catch(() => {
            ElMessage({
              message:"已取消删除操作"
            })
        })
      },
      jumpToGenerate(asset) {
        event.stopPropagation(); // 阻止事件冒泡
        this.$store.commit("setSelectAsset", asset)
        if(this.showDialog){
          this.goBack()
          this.showDialog = false
        }
        this.$router.push("/generate")
      },
      showPopup(itemId) {
        event.stopPropagation(); // 阻止事件冒泡
        // 点击展示弹出框的方法
        if (this.modalVisible === itemId) {
          this.modalVisible = null; // 如果当前点击的和之前的相同，则关闭弹出框
        } else {
          this.modalVisible = itemId; // 否则打开对应的弹出框
        }
      },
      hidePopup() {
        this.modalVisible = null;
      },
      getAssetsList(){
        getAssets({
          page:this.AssetPageData.page,
          page_size:this.AssetPageData.page_size,
          name:this.assetKeyword,
        }).then(response => {
          if(response.status == HttpCodes.SUCCESS){
            this.assets = response.data.data.assets
            this.totalAssets = response.data.data.count
            this.searchAssetIds = Object.values(this.assets).map(item => item.id);
            // 获取assets的pictures
            this.getPicturesList(this.searchAssetIds)
            // 返回assets的长度为0显示站位
            if(this.assets.length == 0){
              this.showAssets = false
              // 不显示站位
            }else{
              this.showAssets = true
            }
          }
        }).catch(error => {
          console.log(error)
        })
      },
      // 获取搜索出来的assets的pictures
      getPicturesList(asset_ids){
        getPictures({
          page:this.PicturePageData.page,
          page_size:this.PicturePageData.page_size,
          asset_ids:asset_ids,
        }).then(response => {
          if(response.status == HttpCodes.SUCCESS){
            this.pictures = response.data.data.pictures
            this.totalPictures = response.data.data.count
            if(this.pictures.length == 0){
              this.showPictures = false
              return
            }
          }
        }).catch(error => {
          console.log(error)
        })
      },
      // 修改vuex中Aseet预览全屏弹出框的展示条件
      goToAssetPreview(asset){
        this.$store.commit("setSelectAsset", asset)
        this.$router.push("/assetpreview")
      },
      // 修改vuex中Picture预览全屏弹出框的展示条件
      goToPicturePreview(picture){
        this.$store.commit("setSelectPicture", picture)
        this.$router.push("/picturepreview")
      },
      toUpload(){
        this.$router.push("upload/")
      },
      // Asset改变页码
      handleCurrentChange(newPage){
        this.AssetPageData.page = newPage
        this.getAssetsList()
      },
      // Asset改变分页大小
      handleSizeChange(size){
        this.AssetPageData.page_size = size
        this.getAssetsList()
      },
      // Picture改变页码
      handlePictureSizeChange(size){
        this.PicturePageData.page_size = size
        this.PicturePageData(this.searchAssetIds)
      },
      // Picture改变分页大小
      handlePictureCurrentChange(newPage){
        this.PicturePageData.page = newPage
        this.getPicturesList(this.searchAssetIds)
      }
    },
    mounted(){
      // 在vuex中获取assetKeyword
      this.assetKeyword = this.$store.state.user.assetKeyword
      // 如果没有assetKeyword返回个人主页面
      if(!this.assetKeyword){
        this.$router.push("/personal")
      }
      this.getAssetsList()
    }
  };
  </script>
  
  <style>

.saerch-content{
    display: flex;
    flex-direction: column;
}
.search-content-title{
    display: flex;
    justify-content: left;
    margin-left: 50px
}
.search-top{
    display: flex;
    justify-content: right;
    margin-right: 50px;
    margin-top: 30px
}
.el-empty-container{
    width: 300px;
    height: 300px;
    margin-left: 50px;
    background-color: #e4e4e6;
}
.goBack-button{
    padding: 15px 25px;
    background-color: rgb(232, 165, 20);
    border-radius: 15%;
}
.goBack-button:hover{
    cursor: pointer;
    background-color: rgb(239, 168, 14);
}


.image-wrapper {
  margin-left: 50px;
  margin-right: 50px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* 添加阴影 */
}

.image-wrapper img {
  width: 300px;
  height: 300px;
}

.image-wrapper:hover {
  cursor: pointer;
}

.image-wrapper:hover::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  height: 300px;
  top: 0;
  width: 300px;
  z-index: 99;
}

.image-wrapper i {
  display: none;
}

.image-wrapper:hover i {
  display: inline-block;
  position: absolute;
  font-size: 40px;
  z-index: 999;
  color: #fff;
  top: 50%;
}

.preview-generate-button {
  width: 80px;
  height: 30px;
  color: black;
  background-color: orange;
  border-radius: 3px;
  font-size: 17px;
  font-style: normal;
  border: None;
}
.modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  right: 8px;
  border: 1px solid black;
  background-color: white;
  z-index: 999;
  border-radius: 5px;
}
.modal-item{
  display: flex;
  padding: 10px;
  font-weight: bold;
}
.modal-item:hover {
  background-color: lightgray;
}
.modal-item-text{
  margin-left: 10px
}
.page-style{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px
}
  </style>