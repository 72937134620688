<template>
    <div id="app">
        <top-navigator>top bar</top-navigator>
        <div class="infoContainer">
            <PersonalNavigator></PersonalNavigator>
            <div class="infoRigt">
                <div class="setting_container">
                    <h3 style="margin: 0px 20px 10px 0px">手机号</h3>
                    <div class="settingItem">
                        <el-input disabled v-model="accountsItems.mobile" ref="input" class="my-el-input-style"></el-input>
                    </div>
                </div>
                <div class="setting_container">
                    <h3 style="margin: 0px 20px 10px 0px">邮箱</h3>
                    <div class="settingItem">
                        <el-input v-model="accountsItems.email" @blur="editEmail" ref="input"></el-input>
                    </div>
                    <span v-if="showEmailError" style="color: red;font-size: small;margin-left: 10px;">{{ emailError }}</span>
                </div>
                <div class="setting_container">
                    <h3 style="margin: 0px 20px 10px 0px">当前身份</h3>
                    <div class="settingItem">
                        <el-input disabled v-model="accountsItems.membership_type" class="my-el-input-style"></el-input>
                    </div>
                </div>
                <div class="setting_container">
                    <h3 style="margin: 0px 20px 10px 0px">身份状态</h3>
                    <div class="settingItem">
                        <el-input disabled v-model="accountsItems.identityStatus" class="my-el-input-style">
                            <template v-slot:append>
                                <span v-if="accountsItems.end_time" style="font-size: small;">{{ accountsItems.end_time }}到期</span>
                                <span style="font-size: small;" v-else>暂无会员状态</span>
                            </template>
                        </el-input>
                    </div>
                </div>
                <div class="setting_container">
                    <h3 style="margin: 0px 20px 10px 0px">可用AI图数量</h3>
                    <div class="settingItem">
                        <el-input disabled v-model="accountsItems.credits" class="my-el-input-style"></el-input>
                    </div>
                </div>
                <div class="button—container">
                    <div class="button button-refresh" @click="refresh()">
                        <el-icon style="height: 20px;width: 20px;margin-right: 5px;margin-top: 3px"><Refresh /></el-icon>
                        <span>刷新</span>
                    </div>
                    <div class="button button-subscribe" @click="toSubscribe()">
                        <el-icon style="height: 20px;width: 20px;margin-right: 5px;margin-top: 3px"><CreditCard /></el-icon>
                        <span>升级会员</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TopNavigator from "@/components/TopBar.vue";
import PersonalNavigator from "./templates/PersonalNavigator.vue";
import { updataUser ,refreshUser} from "@/api";

import { HttpCodes,PricingCodes } from "@/common/const";
import { Refresh,CreditCard} from "@element-plus/icons";
import { ElMessage,ElInput} from "element-plus";
    export default {
        name: "profile-account",
        components:{
            TopNavigator,
            Refresh,
            CreditCard,
            PersonalNavigator,
            ElInput
        },
        data(){
            return{
                accountsItems:{
                    email:"",
                    membership_type:"",
                    identityStatus:"生效中",
                    credits:"",
                    end_time:""
                },
                showEmailError:false,
                emailError:"",
            }
        },
        methods:{
            editEmail() {
                if (!this.validateEmail(this.accountsItems.email)) {
                    this.showEmailError = true
                    this.emailError = '请输入有效的邮箱地址';
                } else {
                    this.showEmailError = false
                    this.emailError = '';
                    const email = JSON.parse(localStorage.getItem("userInfo")).email
                    // 避免重复的邮箱没修改重复向后端发送update请求
                    if(email == this.accountsItems.email){
                        return
                    }else{
                        this.updateUserEmail()
                    }
                }
            },
            // 邮箱格式效验
            validateEmail(email) {
                var re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                return re.test(email);
            },
            // 更新用户邮箱
            updateUserEmail(){
                // const user_id = JSON.parse(localStorage.getItem("userInfo")).user_id
                updataUser({email:this.accountsItems.email}).then(response => {
                    if(response.status == HttpCodes.SUCCESS){
                        const email = response.data.data.email
                        const userInfo = JSON.parse(localStorage.getItem("userInfo"))
                        userInfo.email = email
                        // 更新localStorage中的userInfo中的用户信息
                        this.$store.commit("setUserInfo",userInfo)
                        ElMessage({
                            message: "已成功修改邮箱",
                            type: "success",
                            plain: true,
                        });
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            // 跳转时会员充值页面
            toSubscribe(){
                this.$router.push("/pricing")
            },
            // 刷新账号设置
            refresh(){
                const user_id = JSON.parse(localStorage.getItem("userInfo")).user_id
                refreshUser(user_id).then(response => {
                    this.accountsItems = response.data.data
                    this.accountsItems.identityStatus = "生效中"
                    // 如果充值过会员进行判断是否到期
                    if(this.accountsItems.membership_type != PricingCodes.MEMBERSHIP_FREE){
                        const end_time = new Date(this.accountsItems.end_time);
                        const currentDate = new Date();
                        // 会员已过期
                        if(currentDate > end_time){
                            this.accountsItems.membership_type = PricingCodes.MEMBERSHIP_FREE
                            this.accountsItems.identityStatus = PricingCodes.IDENTITY_STATUS_NON_MEMBER
                        }
                    }
                }).catch(error => {
                    console.log("Failed to get user information:",error)
                })
            },
        },
        mounted(){
            this.refresh()
        }
        
    }
</script>

<style>

html, body, #app {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    width: 100%;
}
.infoContainer {
    display: flex;
    height: 94.5%;
}

.setting_container{
    text-align: left;
    padding: 20px
}
.settingItem{
    display: flex;
    width: 400px;
    border-radius: 10px;
    height: 60px;
}
.button—container{
    padding: 20px
}
.button{
    padding-bottom: 15px;
    padding-top: 15px;
    width: 400px;
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 10px;
    /* background: #edeeef; */
}
.button-refresh {
    background: #edeeef;
}
.button-refresh:hover {
    cursor: pointer;
    background: #d0d2d3; /* 鼠标悬停时的背景颜色 */
}
.button-subscribe {
    background: #f6de60;
}
.button-subscribe:hover {
    cursor: pointer;
    background: #f3d458; /* 鼠标悬停时的背景颜色 */
}
.button span {
    font-size: large;
    font-weight: bolder;
}
.input-text {
    font-size: large;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold; /* 加粗 */
    box-sizing: border-box;
}

.my-el-input-style .el-input__wrapper{
    box-shadow: 0 0 0 0 var(--el-disabled-border-color) inset !important;
}
.my-el-input-style .el-input__wrapper .el-input__inner{
    font-weight: bolder;
    font-size: 16px;
    color: black;
}
.my-el-input-style .el-input-group__append{
    box-shadow: none;
}
</style>