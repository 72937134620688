<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
    <div class="custom-preview">
        <div class="preview-content">
            <!-- 左侧内容 -->
            <div class="preview-content-left button-hover" @click="toTheLeft(selectAssetId)">
                <el-icon style="height: 30px;width: 30px;" v-if="!isFirstAsset"><ArrowLeftBold /></el-icon>
            </div>
            <!-- 中间内容 -->
              <div class="preview-content-center">
                    <div class="preview-content-center-top">
                        <div 
                            @click="goBack()"
                            class="door"
                            style="margin-left: 20px;"
                            >
                            <el-icon style="height: 15px; width: 15px;"><ArrowLeftBold /></el-icon>
                            <span style="margin-left: 10px">返回</span>
                        </div>
                        <div class="doors-container">
                            <div class="door" @click="editAsset()">
                                <el-icon style="height: 15px; width: 15px;"><Edit/></el-icon>
                                <span style="margin-left: 10px">编辑</span>
                            </div>
                            <div class="door" @click="deleteProduct(selectAssetId)">
                                <el-icon style="height: 15px; width: 15px;"><Delete /></el-icon>
                                <span style="margin-left: 10px">删除</span>
                            </div>
                            <div class="door" @click="downloadAssetImage(selectAssetId,transparent_background_url)">
                                <el-icon style="height: 15px; width: 15px;"><Download /></el-icon>
                                <span style="margin-left: 10px">下载</span>
                            </div>
                            <div class="door" style="margin-right: 10px;background-color: #f6de60;" @click="jumpToGenerate(selectAsset)">
                                <span style="padding: 0 20px;">创作</span>
                            </div>
                        </div> 
                    </div>
                  <div class="preview-content-center-bottom">
                    <div style="flex: 1;height: 95%;"></div>
                    <div style="flex: 3;height: 95%;display: flex;justify-content: center;align-items: center;">
                        <div class="main-display-area">
                            <img :src="transparent_background_url" alt="" style="width: 150px;;">
                        </div>
                    </div>
                    <div class="picture-info-container">
                        <div class="info-item">
                            <span>物品名称：</span>
                            <p>{{ selectAssetName }}</p>
                        </div>
                        <div class="info-item">
                            <span>图片大小：</span>
                            <p>{{ selectAssetSize }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 右侧内容 -->
            <div class="preview-content-right button-hover" @click="toTheRight(selectAssetId)">
                <el-icon style="height: 30px;width: 30px;" v-if="!isLastAsset"><ArrowRightBold /></el-icon>
            </div>
        </div>
    </div>
</template>
  
  <script>
  import { getAssets } from "@/api";
  import { Download ,Delete,ArrowLeftBold,ArrowRightBold, Edit} from "@element-plus/icons";
  import {ElMessage, ElMessageBox} from "element-plus";
  import { deleteAsset} from "@/api";
  import { HttpCodes } from "@/common/const";
  export default {
    name: "common-preview-layer",
    components: {
        Download,
        Delete,
        ArrowLeftBold,
        ArrowRightBold,
        Edit
    },
    data(){
        return {
            isFirstAsset:false,
            isLastAsset:false,
            assets:null,
            isMessageVisible:false
        }
    },
    computed:{
          // 准备生成AI图的商品（vuex获取）
            selectAsset(){
                return this.$store.state.user.selectAsset || ""
            },
            selectAssetUrl(){
                return this.$store.state.user.selectAsset.asset_url || ""
            },
            selectAssetName(){
                return this.$store.state.user.selectAsset.name || ""
            },
            selectAssetId(){
                return this.$store.state.user.selectAsset.asset_id || ""
            },
            transparent_background_url(){
                return this.$store.state.user.selectAsset.transparent_background_url
            },
            selectAssetSize(){
                return this.$store.state.user.selectAsset.width + " × "  + this.$store.state.user.selectAsset.height
            }
    },
    // 获取来时的路由
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.previousRoute = from;
        });
    },
    methods: {
        // 删除按钮
        deleteProduct(asset_id){
            event.stopPropagation(); // 阻止事件冒泡
            ElMessageBox.confirm(
                '删除之后，将无法恢复，是否确认删除？',
                '系统提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
            deleteAsset(asset_id)
                .then(response => {
                    if(response.status == HttpCodes.SUCCESS){
                        ElMessage({
                            message:"删除成功",
                            type:"success"
                        })
                        // 如果来时路由时generate页面删除之后回到personal
                        if(this.previousRoute == "/generate/assets"){
                            this.$router.push("/personal")
                        }else{
                            this.$router.go(-1)
                        }
                    }
                })
                .catch(error => {
                    console.error('Error deleting image:', error);
                });
                })
                .catch(() => {
                    ElMessage({
                        message:"已取消删除操作"
                })
            })
        },
        // generate按钮
        jumpToGenerate(asset) {
            this.$store.commit("setSelectAsset", asset)
            this.$router.push("/generate")
        },
        // 返回按钮
        goBack(){
            // 如果来时路由时/generate/assets页面，则不清空setSelectAsset【generate页面只有selectAsset不为空能进】
            if(this.previousRoute.path == "/generate/assets"){
                this.$router.go(-1)
                return
            }
            this.$router.go(-1)
            this.$store.commit("setSelectAsset", {});
        },
        // 下载
        downloadAssetImage(asset_id,asset_url){
            if(asset_id == ""){
                this.showMessage("请选择要下载照片","warning")
                return
            }
            const fileName = asset_id + ".jpg"
            const link = document.createElement('a');
            link.href = asset_url;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        // 编辑
        editAsset(){
            console.log(1)
        },
        // 判断是否是第一个商品图
        isFirst(asset_id){
            const index = this.assets.findIndex(item => item.asset_id === asset_id);
            this.isFirstAsset = (index === 0);
        },
        // 判断是否是最后一个商品图
        isLast(asset_id) {
            const index = this.assets.findIndex(item => item.asset_id === asset_id);
            this.isLastAsset = (index === this.assets.length - 1);
        },
        // 下一个
        toTheRight(currentAssetId) {
            const assets = this.assets
            const index = assets.findIndex(item => item.asset_id === currentAssetId);
            if (index !== -1 && index < assets.length - 1) {
                this.$store.commit("setSelectAsset", assets[index + 1]);
                this.isFirstAsset = false
            } else {
                this.showMessage("已经是最后一个了","warning")
                this.isLastAsset = true;
            }
        },
        // 上一个
        toTheLeft(currentAssetId) {
            const assets = this.assets
            const index = assets.findIndex(item => item.asset_id === currentAssetId);
            if (index > 0) {
                this.$store.commit("setSelectAsset", assets[index - 1]);
                this.isLastAsset = false
            } else if (index === 0) {
                this.isFirstAsset = true;
                this.showMessage("已经是第一个了", 'warning');
            }
        },
        showMessage(message, type) {
            // 检查是否已经有一个消息在显示
            if (this.isMessageVisible) return;
                // 设置消息显示状态为 true
                this.isMessageVisible = true;

                ElMessage({
                message: message,
                type: type,
                plain: true,
                onClose: () => {
                    // 消息关闭后重置为False
                    this.isMessageVisible = false;
                }
            });
        },
        getAssetsList(){
            getAssets().then(response => {
                if(response.status == HttpCodes.SUCCESS){
                    this.assets = response.data.data.assets
                    this.isFirst(this.selectPictureId)
                    this.isLast(this.selectPictureId)
                }
                }).catch(error => {
                    console.error('Get assets error!', error);
            })
        },
    },
    mounted(){
      this.getAssetsList()

    }
  };
  </script>
  
  <style>
  
  /* 自定义全屏弹窗样式 */
  
  
  .edit-icon{
    height: 20px;
    width: 20px;
  }
  .edit-icon:hover{
    cursor: pointer;
  }
  
  .doors-container {
    display: flex;
    gap: 10px; /* 门之间的间距 */
  }
  
  .door {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: hsl(210, 63%, 97%);
    padding: 5px 10px;
    border-radius: 8px;
  }
  .door:hover{
    cursor: pointer;
  }
  .main-display-area{
      background-position: 0px 0px, 10px 10px;
      background-size: 20px 20px;
      background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%),linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
      width: 90%;
      aspect-ratio: 1 / 1;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  </style>