// Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved
import http from "./request"

// 上传商品图接口
export const addAsset = (data) => {
    return http.post("assets/",data)
}
// 获取用户全部商品图
export const getAssets =(params) =>{
    return http.get("assets/",{ 
        params: params,
    })
}
// 删除商品图接口
export const deleteAsset = (asset_id) => {
    return http.delete(`assets/${asset_id}/`)
}
export const getPictures = (params) => {
    return http.get("pictures/",{
        params:params
    })
}

// 删除ai图接口
export const deletePicture = (picture_id) => {
    return http.delete(`pictures/${picture_id}/`)
}
// 生成AI图接口
export const createPictures = (data) => {
    return http.post("asset/createpictures/",data)
}
export const updataPicture = (picture_id,data) => {
    return http.put(`pictures/${picture_id}/`,data)
}

export const getOnePicture = () => {
    const picture_id = JSON.parse(localStorage.getItem("selectPicture")).picture_id
    return http.get(`pictures/${picture_id}/`)
}
// 更新绘画配置接口
export const updateDrawsettings = (user_id,data) =>{
    return http.put(`drawsettings/${user_id}/`,data)
}
// 获取绘画配置接口
export const getDrawsettings = (params) => {
    return http.get("drawsettings/",{params:params})
}
// 获取验证码接口
export const getSmsCode = (data) => {
    return http.post("smscode/",data)
}
// 登录注册接口
export const loginOrRegister = (data) => {
    return http.post("/users/",data)
}
// 重置密码
export const resetPassword = (data) => {
    return http.post("users/pwd/",data)
}
// 获取微信登录二维码
export const getWxCodeUrl = () =>{
    return http.get("login/wechat")
}
export const wxloginPoll = (params) => {
    return http.get("login/wechat/status/",{params:params})
}
// 支付接口
export const OrderPay = (data) => {
    return http.post("/pricing/",data)
}
// 查询订单接口
export const updateOrder = (order_sn, params) => {
    const queryParams = new URLSearchParams(params).toString();
    return http.put(`pricing/${order_sn}/?${queryParams}`);
};

// 获取用户订单列表接口
export const getOrders = (params) => {
    return http.get("/pricing/",{
        params:params,
    })
}
// 更新用户信息接口
export const updataUser = (data) => {
    return http.put(`users/email/`,data)
}
// 刷新用户信息接口
export const refreshUser = (user_id) => {
    return http.get(`users/${user_id}/`)
}
// 用户注销
export const deleteUser = (user_id) => {
    return http.delete(`users/${user_id}/`)
}
// 更新会员接口
export const upgradeMembership = (data) => {
    return http.post("membership/upgrade/",data)
}
export const updateUsername = (data) => {
    return http.put(`users/username/`,data)
}

// 联系接口
export const createContact = (data) => {
    return http.post("create/contact/",data)
}

export const getFonts = () => {
    return http.get("fonts/")
}