// Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved
import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/components/Home.vue'
import Pricing from "@/components/Pricing.vue"
import Contact from "@/components/Contact.vue"
import UploadImage from "@/components/UploadProductImages.vue"
import Assets from "@/components/Assets.vue"
import Pictures from '@/components/Pictures.vue'
import Generate from "@/components/Generate.vue"
import GenerateNavigatorCreate from "@/components/GenerateNavigators/GenerateNavigatorCreate.vue"
import GenerateNavigatorAssets from "@/components/GenerateNavigators/GenerateNavigatorAssets.vue"
import GenerateNavigatorText from "@/components/GenerateNavigators/GenerateNavigatorText.vue"
import GenerateNavigatorSticker from '@/components/GenerateNavigators/GenerateNavigatorSticker.vue'
import GenerateNavigatorSettings from "@/components/GenerateNavigators/GenerateNavigatorSettings.vue"
import VxLogin from "@/components/WxLogin.vue"
import PayPage from "@/components/payPage.vue"
import personal from "@/components/personal.vue"
import Help from "@/components/Help.vue"
import order from "@/components/order.vue"
import PersonalCenter from "@/components/PersonalCenter.vue"
import Search from '@/components/templates/SearchPreview.vue'
import EditPicture from '@/components/EditPicture.vue'
import OrderList from '@/components/OrderComponent/OrderList.vue'
import OrderDetail from '@/components/OrderComponent/OrderDetail.vue'
import Account from '@/components/Account.vue'
import AccountSettings from '@/components/AccountComponent/AccountSettings.vue'
import AccountReset from '@/components/AccountComponent/AccountReset.vue'
import PicturePreview from '@/components/Preview/PicturePreview.vue'
import AssetPreview from '@/components/Preview/AssetPreview.vue'
import EditPictureNavigatorText from '@/components/EditPictureNavigators/EditPictureNavigatorText.vue'
import EditPictureNavigatorSticker from '@/components/EditPictureNavigators/EditPictureNavigatorSticker.vue'

const router = createRouter({
  history: createWebHistory(),  // history 模式
  routes: [
    {
      path:"/home",
      name:"",
      component:Home,
    },
    {
      path: '/personal',
      name: 'home',
      component: personal,
      meta :{
        title: '首页'
      },
    },
    {
      path: '/personal/order',
      name: 'order',
      component: order,
      meta: {
        title: '订单页面'
      },
      children:[
        {
          path:"list",
          name:"订单列表",
          component:OrderList
        },
        {
          path:"detail",
          name:"订单详情",
          component:OrderDetail
        }
      ]
    },
    {
      path: '/personal/center',
      name: 'account',
      component: PersonalCenter,
      meta: {
        title: '个人中心'
      }
    },
    {
      path:"/personal/account",
      name:"settings",
      component:Account,
      meta:{
        title:"账号"
      },
      children:[
        {
          path:"settings",
          name:"账号设置",
          component:AccountSettings
        },
        {
          path:"reset",
          name:"账号修改",
          component:AccountReset
        }
      ]
    },
    {
      path: '/upload',
      name: 'upload',
      component: UploadImage,
      meta :{
        title: '上传商品原图'
      }
    },
    {
      path: '/generate',
      name: 'generate',
      component: Generate,
      meta: {
        title: '创作区'
      },
      children: [
        {
          path: 'create',
          name: '创作',
          component: GenerateNavigatorCreate,
        },
        {
          path: 'assets',
          name: '商品',
          component: GenerateNavigatorAssets
        },
        {
          path: 'layers',
          name: '文字',
          component: GenerateNavigatorText
        },
        {
          path:"sticker",
          name:"贴纸",
          component:GenerateNavigatorSticker
        },
        {
          path: 'settings',
          name: '设置',
          component: GenerateNavigatorSettings
        }
      ]
    },
    {
      path: '/assets',
      name: 'assets',
      component: Assets,
      meta :{
        title: '商品库'
      }
    },
    {
      path:"/pictures",
      name:"pictures",
      component:Pictures,
      meta:{
        title:"AI生成图"
      }
      
    },
    {
      path: '/pricing',
      name: 'pricing',
      component: Pricing,
      meta :{
        title: '充值'
      }
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
       meta :{
        title: '联系我们'
      }
    },
    {
      path: '/help',
      name: 'help',
      component: Help,
      meta :{
        title: '帮助'
      }
    },
    {
      path:"/search",
      name:"saerch",
      component:Search,
      meta:{
        title:"搜索页面"
      }
    },
    {
      path:"/editpicture",
      name:"editpicture",
      component:EditPicture,
      meta:{
        title:"AI图编辑"
      },
      children:[
        {
          path:"text",
          name:"text",
          component:EditPictureNavigatorText
        },
        {
          path:"sticker",
          name:"sticker",
          component:EditPictureNavigatorSticker
        }
      ]
    },
    {
      path:"/picturepreview",
      name:"picturepreview",
      component:PicturePreview,
      meta:{
        title:"AI图预览"
      }
    },
    {
      path:"/assetpreview",
      name:"assetpreview",
      component:AssetPreview,
      meta:{
        title:"商品图预览"
      }
    },
    {
      path: '/wxlogin',
      name: 'wxlogin',
      component: VxLogin,
       meta :{
        title: '微信登录'
      }
    },
    {
      path:"/pay",
      name:"pay",
      component:PayPage,
      meta:{
        title:"支付页面"
      }
    }
  ]
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
  console.log('Navigating to:', to.path, 'from:', from.path);
  // 获取用户信息
  const isAuthenticated = !!localStorage.getItem('userInfo');
  // 检查路径是否允许访问
  const allowedPaths = ['/home', '/wxlogin'];
  // 如果用户未登录且尝试访问非允许路径，则重定向到主页
  if (!isAuthenticated && !allowedPaths.includes(to.path)) {
    return next('/home');
  }
  // 已登录访问
  if(isAuthenticated && to.path == "/"){
    return next("personal")
  }
  // 已登录情况下再次导航进wxlogin页面，返回来时页面
  if(isAuthenticated && to.path == allowedPaths[1]){
    return next(from.path);
  }
  // 导航到订单默认显示订单列表
  if(to.path == "/personal/order"){
    return next("/personal/order/list")
  }
  if(to.path == "/personal/account"){
      return next("/personal/account/settings")
  }
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if(to.path == "/editPicture"){
    return next('/editPicture/text');
  }
  // 如果路径是 "/generate"，默认导航到 "/generate/create"
  if (to.path === '/generate') {
    return next('/generate/create');
  }
  // 继续导航
  next();
});


router.afterEach((to, from)=>{
  console.log(to, from)
  console.log('afterEach')
})

export default router