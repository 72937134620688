<template>
  <div class="color-picker">
    <!-- 取色板 -->
    <div
      class="color-panel"
      ref="colorPanel"
      @mousedown="startPicking"
      @touchstart="startPicking"
      :style="{ background: colorPanelGradient }"
    >
      <div
        class="picker-cursor"
        :style="{ left: cursorX + 'px', top: cursorY + 'px' }"
      ></div>
    </div>

    <div class="color-soliders">
      <div
        style="
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: center;
        "
      >
        <!-- 色相滑块 -->
        <div class="slider-item">
          <div> <span>色相</span></div>
          <div class="slider-container">
            <input
              type="range"
              ref="hueSlider"
              min="0"
              max="360"
              step="1"
              v-model="hue"
              class="slider hue-slider"
              @input="updatePanelBackground"
            />
          </div>
        </div>
        <!-- 透明度滑块 -->
        <div class="slider-item">
          <div><span style="margin: 0;">不透明度</span></div>
          <div class="slider-container">
            <input
              type="range"
              ref="alphaSlider"
              min="0"
              max="1"
              step="0.01"
              v-model="alpha"
              class="slider alpha-slider"
              @input="updateColor"
            />
          </div>
        </div>
      </div>

      <div>
        <div
          class="color-preview"
          :style="{ backgroundColor: rgbaColor }"
        ></div>
      </div>
    </div>
    <div class="color-input-container">
      <div>
        <el-dropdown trigger="click">
          <div style="display: flex;justify-content: center;align-items: center;cursor: pointer;">
            <span>{{ colorFormat }}</span>
            <el-icon style="height: 15px;width: 15px;margin-left: 5px;"><ArrowDown /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item 
                v-for="item in colorFormats" 
                :key="item.value"
                @click="changeColorFormat(item.value)">
                {{ item.label }}
              </el-dropdown-item>
            </el-dropdown-menu>
         </template>
        </el-dropdown>
      </div>
      <div>
        <el-input placeholder="请输入" style="width: 230px;" v-model="userInputColor" @change="handleUserInputColor"></el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { ElDropdownMenu,ElDropdown,ElDropdownItem,ElInput,ElMessage } from 'element-plus';
import { ArrowDown } from '@element-plus/icons';
export default {
  components:{
    ElDropdownMenu,
    ElDropdown,
    ElDropdownItem,
    ElInput,
    ArrowDown
  },
  props: {
    selectedColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hue: 0, // 色相值
      alpha: 1, // 透明度
      cursorX: 0, // 光标的X位置
      cursorY: 0, // 光标的Y位置
      saturation: 100, // 当前饱和度
      lightness: 50, // 当前亮度
      colorPanelGradient: "", // 取色板的背景
      userInputColor:"#000000",
      colorFormat:"HEX",
      colorFormats:[
        { label:"HEX", value:"HEX" },
        { label:"rgba", value:"rgba" },
      ]
    };
  },
  computed: {
    // 根据当前色相、饱和度、亮度和透明度计算 RGBA 颜色
    rgbaColor() {
      return `hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, ${this.alpha})`;
    },
  },
  methods: {
    // 初始化颜色
    initializeColor() {
      const rgba = this.getRgba(this.selectedColor);
      const hsl = this.rgbToHsl(rgba.r, rgba.g, rgba.b);
      this.hue = hsl.h;
      this.saturation = hsl.s * 100;
      this.lightness = hsl.l * 100;
      this.alpha = rgba.a;
      this.updatePanelBackground();
    },
    getRgba(rgba) {
      // 使用正则表达式匹配 rgba 格式，支持带有空格和无空格的格式
      const match = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)$/i);
      // 如果不是rgba格式，则是十六进制，将十六进制转换为rgba
      if (!match) {
        return this.hexToRgba(rgba)
        
      }

      // 提取 R, G, B 值和 A 值（透明度）
      const r = parseInt(match[1]);
      const g = parseInt(match[2]);
      const b = parseInt(match[3]);
      const a = match[4] !== undefined ? parseFloat(match[4]) : 1; // 如果没有提供 A 通道，默认透明度为 1

      // 返回 RGBA 对象
      return { r, g, b, a };
    },
    // 更新取色板背景
    updatePanelBackground() {
      this.colorPanelGradient = `linear-gradient(to top, black,rgba(0, 0, 0, 0)), 
      linear-gradient(to right, white, hsl(${this.hue}, 100%, 50%))`;  // 改变透明到黑色的渐变顺序
      this.updateSlidersBackground();
      this.updateColor(); // 在色相变化时更新颜色
    },

    // 更新滑块背景
    updateSlidersBackground() {
      this.$refs.alphaSlider.style.background = `linear-gradient(to right, rgba(255, 255, 255, 0), hsla(${this.hue}, ${this.saturation}%, ${this.lightness}%, 1))`;
    },

    // 启动取色功能
    startPicking(event) {
      const panel = this.$refs.colorPanel;
      const rect = panel.getBoundingClientRect();
      const onMouseMove = (e) => {
        const x = (e.touches ? e.touches[0].clientX : e.clientX) - rect.left;
        const y = (e.touches ? e.touches[0].clientY : e.clientY) - rect.top;

        // 限制光标在面板范围内
        this.cursorX = Math.min(Math.max(x, 0), rect.width);
        this.cursorY = Math.min(Math.max(y, 0), rect.height);

        // 更新饱和度和亮度
        this.saturation = (this.cursorX / rect.width) * 100;
        this.lightness = 100 - (this.cursorY / rect.height) * 100;

        this.updateColor();
      };

      const stopPicking = () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", stopPicking);
        window.removeEventListener("touchmove", onMouseMove);
        window.removeEventListener("touchend", stopPicking);
      };

      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", stopPicking);
      window.addEventListener("touchmove", onMouseMove);
      window.addEventListener("touchend", stopPicking);

      // 初次触发
      onMouseMove(event);
    },

    // 更新当前颜色
    updateColor() {
      const newColor = this.formatToRgba(this.hue,this.saturation,this.lightness)
      // 发送颜色更新事件到父组件
      this.$emit("color-changed", newColor);  // 发送事件并传递颜色值
    },
    // 格式颜色为rgba格式
    formatToRgba(h, s, l) {
      // 将色相 h 转换为 0-1 范围
      h = h / 360;

      // 将饱和度和亮度转换为 0-1 范围
      s = s / 100;
      l = l / 100;

      const c = (1 - Math.abs(2 * l - 1)) * s;
      const x = c * (1 - Math.abs((h * 6) % 2 - 1));
      const m = l - c / 2;

      let r, g, b;

      if (h < 1 / 6) {
        r = c;
        g = x;
        b = 0;
      } else if (h < 2 / 6) {
        r = x;
        g = c;
        b = 0;
      } else if (h < 3 / 6) {
        r = 0;
        g = c;
        b = x;
      } else if (h < 4 / 6) {
        r = 0;
        g = x;
        b = c;
      } else if (h < 5 / 6) {
        r = x;
        g = 0;
        b = c;
      } else {
        r = c;
        g = 0;
        b = x;
      }

      // 将 RGB 值加上 m 并转为 0-255 范围
      r = Math.round((r + m) * 255);
      g = Math.round((g + m) * 255);
      b = Math.round((b + m) * 255);
      const a = this.alpha
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    // 16进制颜色转RGB
    hexToRgba(hex) {
      const r = parseInt(hex.substring(1, 3), 16);
      const g = parseInt(hex.substring(3, 5), 16);
      const b = parseInt(hex.substring(5, 7), 16);
      return { r, g, b, a: 1 };
    },
    // RGB转HSL
    rgbToHsl(r, g, b) {
      r /= 255;
      g /= 255;
      b /= 255;
      let max = Math.max(r, g, b);
      let min = Math.min(r, g, b);
      let h = 0,
        s = 0,
        l = (max + min) / 2;
      if (max !== min) {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        if (max === r) {
          h = (g - b) / d + (g < b ? 6 : 0);
        } else if (max === g) {
          h = (b - r) / d + 2;
        } else {
          h = (r - g) / d + 4;
        }
        h /= 6;
      }
      return { h: h * 360, s, l };
    },
    // 改变颜色输入格式
    changeColorFormat(format){
      if(format == "rgba"){
        const rgba = this.hexToRgba(this.userInputColor)
        const newColor = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
        this.userInputColor = newColor
      }
      if(format == "HEX"){
        this.userInputColor = '#000000'
      }
    },
    // 用户颜色输入处理
    handleUserInputColor(){
      if(this.colorFormat == "HEX"){
        const rgba = this.hexToRgba(this.userInputColor)
        const newColor = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
        this.$emit("color-changed", newColor);
        return
      }
      if(this.colorFormat == "rgba"){
        const match = this.userInputColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)$/i);
        if(!match){
          ElMessage({
            message:"请输入正确格式的rgba颜色",
            type:"warning"
          })
        }else{
          this.$emit("color-changed", this.userInputColor);
        }
      }
    }
  },
  mounted() {
    this.initializeColor(); // 初始化颜色
  },
};
</script>

<style scoped>
.color-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 320px;
}

.color-panel {
  position: relative;
  width: 300px;
  height: 200px;
  border: 1px solid #ccc;
  cursor: crosshair;
}

.picker-cursor {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: white;
  border: 2px solid black;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
}
.color-soliders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 300px;
}
.slider-item {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.slider-item span {
  white-space: nowrap;
}
.slider-container {
  width: 100%;
  display: flex;
  justify-content: right;
}

.slider {
  width: 180px;
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.hue-slider {
  background: linear-gradient(
    to right,
    red,
    yellow,
    lime,
    cyan,
    blue,
    magenta,
    red
  );
}
.alpha-slider {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), hsla(0, 100%, 50%, 1));
  }

.color-preview {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.color-input-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}
</style>
