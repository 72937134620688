<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div class="pricing-page">
    <top-navigator>top bar</top-navigator>
    <div class="pricing-container">
      <div>
        <div style="width: 730px; display: flex; justify-content: center">
          <div class="plan-buttons">
            <div
              class="plan-button"
              :class="{ selected: selectedBasicOrPro === 'Basic' }"
              @click="selectBasicOrPro('Basic')"
            >
              <span>Basic</span>
            </div>
            <div
              class="plan-button"
              :class="{ selected: selectedBasicOrPro === 'Pro' }"
              @click="selectBasicOrPro('Pro')"
            >
              <span>Pro</span>
            </div>
          </div>
        </div>
        <div class="recharge-options">
          <div
            v-for="item in filteredPlans"
            :key="item.value"
            :class="[
              'recharge-option',
              { selected: selectedPlan === item.value },
            ]"
            @click="selectPlan(item.value)"
          >
            <div class="plan-info">
              <p style="font-size: 15px; color: darkgray">{{ item.label }}</p>
              <h3 style="font-size: 30px; margin-top: 5px; margin-bottom: 5px">
                ￥{{ item.value }}
              </h3>
              <p style="font-size: 15px; color: darkgray">
                仅￥{{ item.day }}/天
              </p>
              <div class="recharge-desc">
                <el-icon style="width: 15px;height: 15px;margin-right: 5px"><SuccessFilled /></el-icon>
                <p>{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 已选套餐显示 -->
        <div class="selected-package">
          <div style="width: 100%;display: flex;justify-content: space-between;">
            <span style="font-weight: bolder">已购套餐:</span>
            <span
              style="color: darkgray; font-size: 15px"
              >{{ selectedPlanLabel }}</span
            >
          </div>
          <span v-if="deadline" style="color: #e88b0c; margin-top: 5px">开通会员有效期至 {{ deadline }}</span>
        </div>

        <!-- 支付方式选择 -->
        <div class="payment-methods">
          <span style="margin-right: 10px; margin-top: 10px">支付方式：</span>
          <div style="position: relative">
            <el-radio
              v-for="pay in PayPlans"
              :key="pay.label"
              :label="pay.label"
              v-model="paymentMethod"
              class="payment-method"
              :class="{ selected: paymentMethod === pay.label }"
              @click="selectPaymentMethod(pay.label)"
            >
              <div class="method">
                <img :src="pay.src" alt="" />
                <span>{{ pay.label }}</span>
              </div>
              <div v-if="this.paymentMethod == pay.label" class="activeCls"></div>
            </el-radio>
          </div>
        </div>
        <!-- 同意条款说明 -->
        <div class="terms">
          <input type="checkbox" v-model="agreement" id="agreement" />
          <label
            >我已阅读并同意<span
              class="xy-class"
              style="font-size: small"
              @click="openDialog"
              >《服务协议》</span
            ></label
          >
        </div>
        <!-- 充值按钮 -->
        <div class="submit">
          <el-button size="large" @click="handleRecharge" type="primary"
            >充值</el-button
          >
        </div>
      </div>
    </div>

    <el-dialog width="500px" :show-close="false" v-model="showAgreeDialog">
      <div class="pricing-dialog-container">
        <h1>请仔细阅读服务协议</h1>
        <div class="pricing-file-content" v-if="fileContent">
          {{ fileContent }}
        </div>
        <div class="pricing-dialog-btn" @click="openDialog()">
          <span>关闭</span>
        </div>
      </div>
    </el-dialog>
    <ErrorDialog v-model="dialogData.isModalVisible" @confirm="confirm">
      <div style="height: 300px">
        <img
          :src="dialogData.errorImage"
          alt=""
          style="width: 200px; height: 200px"
        />
        <p>{{ dialogData.errorMessage }}</p>
      </div>
    </ErrorDialog>
  </div>
</template>
<script>
import { SuccessFilled } from "@element-plus/icons";
import TopNavigator from "@/components/TopBar.vue";
import { ElMessageBox, ElMessage, ElRadio, ElDialog } from "element-plus";
import { ElButton } from "element-plus";
import { OrderPay, upgradeMembership,refreshUser } from "@/api";
import { HttpCodes,PricingCodes } from "@/common/const";
import ErrorDialog from "./templates/ErrorDialog.vue";

export default {
  name: "PricingPage",
  components: {
    TopNavigator,
    ElButton,
    ElRadio,
    ElDialog,
    ErrorDialog,
    SuccessFilled
  },
  data() {
    return {
      dialogData: {
        isModalVisible: false,
        errorImage: require("../assets/images/logo/error.jpg"),
        errorMessage: "",
      },
      fileContent: "",
      showAgreeDialog: false,
      selectedPlan: "",
      paymentMethod: "",
      deadline: "",
      agreement: true,
      plans: [
        { id: 1, label: "年卡（365天）", value: "499", day: "1.37",desc:"1,000 images every month" },
        { id: 2, label: "季卡（90天）", value: "199", day: "2.21" ,desc:"1,000 images every month"},
        { id: 3, label: "月卡（30天）", value: "79", day: "2.61" ,desc:"1,000 images every month"},
        // TODO:pro会员套餐
        { id: 4, label: "年卡（365天）", value: "599", day: "1.88" ,desc:"3,000 images every month"},
        { id: 5, label: "季卡（90天）", value: "299", day: "2.5" ,desc:"3,000 images every month"},
        { id: 6, label: "月卡（30天）", value: "179", day: "2.61",desc:"3,000 images every month" },
      ],
      PayPlans: [
        {
          id: 1,
          label: "微信支付",
          src: require("../assets/images/logo/wechat-logo.jpg"),
        },
        {
          id: 2,
          label: "支付宝",
          src: require("../assets/images/logo/zfb.png"),
        },
        {
          id: 3,
          label: "对公汇款",
          src: require("../assets/images/logo/dghk.png"),
        },
      ],
      selectedBasicOrPro: "Basic",
      payForm: {
        payment_type: "",
        order_amount: 20.21,
        subscribe_type: "YEARLY_PRO",
      },
      upgrateData: {
        user_id: "",
        current_membership: "",
        subscribe_type: "",
      },
      current_membership_type:""
    };
  },
  computed: {
    selectedPlanLabel() {
      const plan = this.plans.find((p) => p.value === this.selectedPlan);
      return plan ? plan.label : "";
    },
    // 当为Basic加载plans中的前三条，为Pro时候加载后三条
    filteredPlans() {
      if (this.selectedBasicOrPro == "Pro") {
        return this.plans.slice(-3);
      } else {
        return this.plans.slice(0, 3);
      }
    },
  },
  methods: {
    // 弹框确认按钮
    confirm() {
      this.dialogData.isModalVisible = false;
    },

    selectPlan(plan) {
      this.selectedPlan = plan;
      const date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      const day = date.getDate();
      if (["499", "599"].includes(plan)) {
        year += 1;
      } else if (["199", "299"].includes(plan)) {
        month += 3;
      } else {
        month += 1;
      }
      if (month > 12) {
        month -= 12;
        year += 1;
      }
      this.deadline = `${year}-${month < 10 ? "0" + month : month}-${
        day < 10 ? "0" + day : day
      }`;
    },
    selectPaymentMethod(method) {
      this.paymentMethod = method;
    },
    checkAgreement() {
      if (!this.agreement) {
        alert("对不起");
      }
    },
    handleRecharge() {
      // 获取订阅类型
      const subscribe_type = this.getSubscribeType(this.selectedPlan);
      if (subscribe_type == null) {
        this.dialogData.isModalVisible = true;
        this.dialogData.errorMessage = "请先选择套餐";
        return;
      }
      this.payForm.subscribe_type = subscribe_type;
      this.payForm.order_amount = this.selectedPlan;
      if (!this.agreement) {
        this.dialogData.isModalVisible = true;
        this.dialogData.errorMessage = "您需要同意服务协议才能完成充值";
        return;
      } else if (!this.paymentMethod) {
        this.dialogData.isModalVisible = true;
        this.dialogData.errorMessage = "您需要选择一个支付方式";
        return;
        // this.showElMessageBox("您需要选择一个支付方式")
      } else {
        if (this.paymentMethod == "微信支付") {
          this.payForm.payment_type = "wxpay";
        } else if (this.paymentMethod == "支付宝") {
          this.payForm.payment_type = "alipay";
        }
        let user_id = JSON.parse(localStorage.getItem("userInfo")).user_id;
        // 如果已有会员
        if (this.current_membership_type !== "FREE") {
          // 不能进行降级
          if (
            ["MONTHLY_PRO", "YEARLY_PRO"].includes(this.current_membership_type) &&
            ["MONTHLY", "SEASON", "YEARLY"].includes(subscribe_type)
          ) {
            ElMessage({
              message: "不能进行降级",
              type: "info",
            });
            return;
          }
          // 当前为YEARLY_PRO
          if (this.current_membership_type === "YEARLY_PRO") {
            ElMessage({
              message: "不能进行降级",
              type: "info",
            });
            return;
          }
          // Basic升级为Pro
          if (
            ["MONTHLY", "SEASON", "YEARLY"].includes(this.current_membership_type) &&
            ["MONTHLY_PRO", "YEARLY_PRO", "SEASON_PRO"].includes(subscribe_type)
          ) {
            this.upgrateData.user_id = user_id;
            this.upgrateData.current_membership = this.current_membership_type;
            this.upgrateData.subscribe_type = "MONTHLY_PRO";
            this.showConfirmAndPay();
          } else {
            this.pay();
          }
        } else {
          this.pay();
        }
      }
    },
    // Basic升级为Pro会员
    showConfirmAndPay() {
      ElMessageBox.confirm(
        "Basic升级为Pro，您的Basic会员天数余额将自动转换为对应的Pro会员天数余额",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
        }
      )
        .then(() => {
          upgradeMembership(this.upgrateData)
            .then((response) => {
              if (response.status == HttpCodes.SUCCESS) {
                const userInfo = JSON.parse(localStorage.getItem("userInfo"));
                // 如果进来这个if则后端已经成功返回，一定会有membership_type字段
                console.log(
                  "upgrate membership success",
                  response.data.data.membership_type
                );
                userInfo.membership_type = response.data.data.membership_type;
                userInfo.credits = 3000;
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
                ElMessage({
                  message: "升级会员成功!",
                  type: "success",
                });
                this.getUserMembershipType()
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {
          return;
        });
    },
    // 支付请求函数
    pay() {
      OrderPay(this.payForm)
        .then((response) => {
          if (response.status == HttpCodes.SUCCESS) {
            // 后端返回的订单信息，以及支付的code_url先保存
            console.log(response.data.data);
            localStorage.setItem("order", JSON.stringify(response.data.data));
            this.$router.push("/pay");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取充值会员类型
    getSubscribeType(plan) {
      switch (plan) {
        case "499":
          return "YEARLY";
        case "199":
          return "SEASON";
        case "79":
          return "MONTHLY";
        case "599":
          return "YEARLY_PRO";
        case "299":
          return "SEASON_PRO";
        case "179":
          return "MONTHLY_PRO";
        default:
          return null;
      }
    },
    showElMessageBox(text) {
      ElMessageBox.confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      })
        .then(() => {
          ElMessage({
            type: "success",
            message: "Delete completed",
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    selectBasicOrPro(plan) {
      this.selectedBasicOrPro = plan;
      if (plan == "Basic") {
        this.selectPlan(this.plans[0].value);
      } else {
        // 切换pro默认选中pro年卡
        this.selectPlan(this.plans[3].value);
      }
    },
    openDialog() {
      if (this.showAgreeDialog) {
        this.showAgreeDialog = false;
        return;
      } else {
        this.showAgreeDialog = true;
        fetch("/files/service_agreement.txt")
          .then((response) => response.text())
          .then((data) => {
            this.fileContent = data; // 将文件内容保存到 fileContent 中
          })
          .catch((error) => {
            console.error("Error loading the file:", error);
          });
      }
    },
    // 实时获取用户会员状态，避免会员过期用户不能进行充值
    getUserMembershipType(){
      const user_id = JSON.parse(localStorage.getItem("userInfo")).user_id
      refreshUser(user_id).then(response => {
        console.log(response.data.data)
        const membership_type = response.data.data.membership_type
        // 如果后端返回的会员不为FREE,则判断会员是否过期
        if(membership_type != PricingCodes.MEMBERSHIP_FREE ){
          // 获取会员到期时间
          const end_time = new Date(response.data.data.end_time);
          const currentDate = new Date();
          // 判断是否过期
          if(currentDate > end_time){
            this.current_membership_type = PricingCodes.MEMBERSHIP_FREE
          }else{
            this.current_membership_type = response.data.data.membership_type
          }

        }
      }).catch(error => {
        console.log("Failed to get user information:",error)
      })
    }
  },
  mounted() {
    this.getUserMembershipType()
    // 进入页面选中年卡
    this.selectPlan(this.plans[0].value);
  },
};
</script>

<style scoped>

/* 选中的对钩样式 */
.activeCls {
  position: absolute;
  right: 0;
  bottom: 0;
}

.activeCls:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  border: 12px solid #f90;
  border-top-color: transparent;
  border-left-color: transparent;
}

.activeCls:after {
  content: "";
  width: 5px;
  height: 10px;
  position: absolute;
  right: 4px;
  bottom: 5px;
  border: 1px solid #fff;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}
/*  */


.pricing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plan-buttons {
  margin-left: 50px;
  margin-top: 10px;
  display: flex;
  background-color: #f6f8fa;
  width: 150px;
  border-radius: 15px;
  padding: 8px;
}
.plan-button {
  margin-left: 15px;
  padding: 10px 10px;
  border-radius: 10px;
}
.plan-button.selected {
  background-color: #ffd700;
}
.plan-button:hover {
  cursor: pointer;
}

.recharge-options {
  margin-left: 50px;
  margin-top: 30px;
  width: 730px;
  height: 400px;
  text-align: center;
  display: flex;
  margin-bottom: 20px;
}

.recharge-option {
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  text-align: center;
  border: 2px solid black;
  border-radius: 30px;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;
}

.recharge-option.selected {
  border-color: #ffd700;
  color: #ffd700;
}

.recharge-option .plan-info {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plan {
  margin-top: 30px;
  display: flex !important;
  /* flex-direction: column; */
  /* align-items: center; */
}

.recharge-option .plan-info span,
.recharge-option .plan-info h3,
.recharge-option .plan-info p {
  margin: 0;
}

.selected-package {
  margin-left: 70px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 220px;
}

.payment-methods {
  margin-left: 70px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
}

.payment-method {
  width: 132px;
  height: 53px;
  border: 1px solid #D3D3D3;
  padding-left: 5px;
  cursor: pointer;
}
.method {
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
}

.payment-method img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.payment-method.selected {
  border-color: #f90;
  color: #f90;
}

.terms {
  display: flex;
  margin-left: 70px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
}

.submit {
  display: flex;
  margin-left: 70px;
  margin-top: 20px;
}
.xy-class {
  font-size: small;
}
.xy-class:hover {
  color: #340cd6;
  cursor: pointer;
}
.pricing-dialog-container {
  margin-top: 50px;
  height: 300px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.pricing-file-content {
  flex: 1; /* 占据剩余空间 */
  width: 96%;
  max-height: 60%;
  white-space: pre-wrap; /* 保留空格和换行 */
  font-family: monospace; /* 使用等宽字体，使文本格式更接近原始文件 */
  padding: 10px;
  overflow-y: auto; /* 如果内容太多，则显示垂直滚动条 */
}

.pricing-dialog-btn {
  position: absolute;
  right: 20px;
  bottom: 1px;
  cursor: pointer;
  flex-shrink: 0; /* 防止按钮缩小 */
  color: rgb(73, 100, 32);
}
.pricing-dialog-btn:hover {
  color: rgb(51, 78, 10);
}
.recharge-desc{
  display: flex;
  margin-top: 5px;
}
.recharge-desc p{
  font-size: small
}
</style>