// drag.js
import store from "@/store";
/**
 * 给指定的元素添加拖拽事件，使其在父容器内可拖动。
 * @param {HTMLElement} element - 需要拖拽的元素
 */
export function addElementDragEvent(element,divID='container') {
    // 获取父容器
    const container = document.getElementById(divID);
    let isDragging = false;
    let startX, startY, initialX, initialY;

    // 为元素添加鼠标按下事件
    element.addEventListener('mousedown', (e) => {
        e.preventDefault()
        isDragging = true;
        startX = e.clientX;
        startY = e.clientY;
        initialX = parseInt(element.style.left) || 0;
        initialY = parseInt(element.style.top) || 0;
        e.preventDefault();
    });

    // 鼠标移动时更新元素位置
    document.addEventListener('mousemove', (e) => {
        e.preventDefault()
        if (isDragging) {
            const deltaX = e.clientX - startX;
            const deltaY = e.clientY - startY;

            let newLeft = initialX + deltaX;
            let newTop = initialY + deltaY;

            // 获取父容器的宽度和高度
            const containerWidth = container.offsetWidth;
            const containerHeight = container.offsetHeight;

            // 获取元素的宽度和高度
            const elementWidth = element.offsetWidth;
            const elementHeight = element.offsetHeight;

            // 限制元素位置不超出父容器的范围
            if (newLeft < 0) newLeft = 0;
            if (newTop < 0) newTop = 0;
            if (newLeft > containerWidth - elementWidth) newLeft = containerWidth - elementWidth;
            if (newTop > containerHeight - elementHeight) newTop = containerHeight - elementHeight;

            // 更新元素位置
            element.style.left = newLeft + 'px';
            element.style.top = newTop + 'px';
        }
    });

    // 鼠标松开时结束拖拽
    document.addEventListener('mouseup', (e) => {
        e.preventDefault()
        if(isDragging){
            saveHistoryState(element)
        }
        isDragging = false
    });
}

/**
 * 设置指定元素的样式。
 *
 * @param {string} elementUniqueId - 需要修改样式的元素的唯一标识ID。
 * @param {string} divId - 目标容器的ID，元素将被查找并更新样式。
 */
export function setSelectedElementStyle(elementUniqueId,divId){
    // 获取此次选择的对象
    const selectedElement = document.getElementById(elementUniqueId)
    // 获取透明区域
    const container = document.getElementById(divId);
    // 查询出透明区域所有对象
    const allElements = container.querySelectorAll(".DT-sticker-container,.DT-text-container,#asset,#photo")
    // 初始化对象，默认都不显示边框
    Array.from(allElements).forEach(el => el.classList.remove('selected'));
    // 设置此次选择的对象显示边框
    selectedElement.classList.add('selected');
}
/***
  * 创建一个贴纸元素，并将其添加到指定的容器中。
 -* @param {string} imageUrl - 贴纸图片的URL。
- * @param {string} divID - 目标容器的ID，贴纸将被添加到该容器中。
- * @param {number} [scale=1] - 贴纸的缩放比例，默认值为 1。
- * @param {string} [left='0px'] - 贴纸在容器内的水平位置，默认为 '0px'。
- * @param {string} [top='0px'] - 贴纸在容器内的垂直位置，默认为 '0px'。 
 */
export function createStcikerElement(imageUrl,divID='container',scale=1, left='0px', top='0px'){
    // 获取父容器
    const container = document.getElementById(divID);
    // 贴纸区域最外层，因为需要移动则需要将div的style的position设置为absolute
    const div = document.createElement('div')
    div.classList.add('sticker-container')
    // 创建stickerContainer，因为有顶点显示，需要将position设置为relative
    const stickerContainerDiv = document.createElement('div')
    // 创建img标签
    const stickerImg = document.createElement('img');
    stickerImg.src = imageUrl
    stickerImg.style.width = stickerImg.naturalWidth * scale + "px"
    stickerImg.style.height = 'auto'; // 设置高度为自动（根据宽度自动调整）
    // 将img添加到stickerContainerDiv中
    stickerContainerDiv.appendChild(stickerImg)
    // 创建点
    stickerContainerDiv.classList.add('DT-sticker-container');
    // 贴纸的唯一ID
    const uniqueId = `sticker-container-${Date.now()}`;
    stickerContainerDiv.id = uniqueId
    stickerContainerDiv.addEventListener('click', () => setSelectedElementStyle(uniqueId, divID));
    // 贴纸顶点l类【每个点不同位置】
    const stickerPointClasses = ["sticker-top-left", "sticker-top-right", "sticker-bottom-left", "sticker-bottom-right"];
    // 循环创建四个点
    for (let i = 0; i < 4; i++) {
        // 创建一个新的 div 元素
        const stickerDianDiv = document.createElement('div');

        // 添加两个类：共同的类 'sticker-dian' 和循环中每个 div 的类
        stickerDianDiv.classList.add("sticker-dian", stickerPointClasses[i]);

        // 将新创建的 div 元素添加到父容器中
        stickerContainerDiv.appendChild(stickerDianDiv)

        // 为每个点添加缩放事件
        stickerDianDiv.addEventListener('mousedown', (e) => {
            e.stopPropagation();

            const initialWidth = parseInt(stickerImg.style.width);
            const initialX = e.clientX;
            let aspectRatio = div.getBoundingClientRect().height / div.getBoundingClientRect().width

            // 记录初始坐标
            const initialLeft = parseInt(div.style.left) || 0;
            const initialTop = parseInt(div.style.top) || 0;

            const positionClass = stickerDianDiv.classList[1];

            // 鼠标移动事件
            const mouseMoveHandler = (e) => {
                e.stopPropagation()
                // 鼠标移动记录
                let deltaX = e.clientX - initialX;
                // 以左上顶点进行缩放
                if (positionClass === "sticker-top-left") {
                    const newWidth = Math.max(initialWidth - deltaX, 50);
                    // 更新宽度和高度
                    stickerImg.style.width = newWidth + 'px';
                    stickerImg.style.height = newWidth * aspectRatio + "px"
                    // 更新左上角的位置，保持左边不变
                    div.style.left = initialLeft + deltaX + 'px';
                    div.style.top = initialTop + deltaX * aspectRatio + 'px'
                }
                // 以右上进行缩放
                if (positionClass === "sticker-top-right") {
                    // 根据鼠标水平移动的距离 deltaX 计算新的宽度
                    const newWidth = Math.max(initialWidth + deltaX, 50);  
                    // 更新图片的宽度和高度
                    stickerImg.style.width = newWidth + 'px';
                    stickerImg.style.height = newWidth * aspectRatio + "px"
                    // 根据鼠标移动更新图片的顶部位置（使图片上边缘跟随鼠标移动）
                    div.style.top = initialTop - deltaX * aspectRatio + 'px'
                }
                // 以左下进行缩放
                if (positionClass === "sticker-bottom-left") {
                    // 根据鼠标水平移动的距离 deltaX 计算新的宽度
                    const newWidth = Math.max(initialWidth - deltaX, 50);  

                    // 更新图片的宽度和高度
                    stickerImg.style.width = newWidth + 'px';
                    stickerImg.style.height = newWidth * aspectRatio + "px"

                    // 更新左下角的位置，保持左边不变
                    div.style.left = initialLeft + deltaX + 'px';
                }
                // 以右下进行缩放
                if (positionClass === "sticker-bottom-right") {
                    const newWidth = Math.max(initialWidth + deltaX, 50);
                    // 更新宽度和高度
                    stickerImg.style.width = newWidth + 'px';
                    stickerImg.style.height = newWidth * aspectRatio + "px"
                }
            };
            // 鼠标松开事件
            const mouseUpHandler = () => {
                saveHistoryState(div)
                document.removeEventListener('mousemove', mouseMoveHandler);
                document.removeEventListener('mouseup', mouseUpHandler);
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        });

    }
    // 将stickerContainerDiv添加到最外层div
    div.appendChild(stickerContainerDiv)
    div.style.left = left
    div.style.top = top
    // 将div添加到透明背景区域container中
    container.appendChild(div)
    // 为贴纸添加拖动事件
    addElementDragEvent(div, divID)
    createElementRecord(uniqueId)
    saveHistoryState(div)
}


// 没新建一个贴纸就创建一个记录来保存它的历史状态
export function createElementRecord(uniqueId){
    // 在vuex中创建一个贴纸数组来保存位置、大小信息
    if(uniqueId.startsWith("sticker")){
        store.commit("setStickersHistoryState", uniqueId);
    }
    // 文字
    if(uniqueId.startsWith("text")){
        store.commit("setTextsHistoryState", uniqueId);
    }
}

export function saveHistoryState(element) {
    // 获取元素的位置标识
    const uniqueId = element.querySelector(".DT-sticker-container, .DT-text-container").id;

    // 判断该元素是贴纸还是文本
    if (uniqueId.startsWith("sticker")) {
            // 创建一个对象 stateData 来保存元素的状态，包括位置和尺寸
        const stateData = {
            position: { left: element.style.left, top: element.style.top},
            style: {
                width: parseFloat(element.getBoundingClientRect().width) - 4 + "px",
                height: parseFloat(element.getBoundingClientRect().height) - 4 + "px"
            }
        };
        // 将 uniqueId 和 stateData 作为对象传递给 mutation
        store.commit("saveStickersHistoryState", { uniqueId, stateData });
    }
    if(uniqueId.startsWith("text")) {
        const textContainer = element.querySelector(".DT-text-container")
        const pTag = textContainer.querySelector("p")
        const stateData = {
            // 位置信息
            position: { 
                left: element.style.left || "0px", 
                top: element.style.top || "0px" , 
                width: element.getBoundingClientRect().width + "px"
            },
            // 字体样式
            style:{
                textContent: pTag.textContent,
                fontFamily: textContainer.style.fontFamily,
                fontWeight: textContainer.style.fontWeight,
                textAlign: textContainer.style.textAlign,
                fontSize: textContainer.style.fontSize,
                lineHeight: textContainer.style.lineHeight,
                letterSpacing:textContainer.style.letterSpacing,
                textDecoration: textContainer.style.textDecoration || "",
                color: textContainer.style.color,
                backgroundColor: textContainer.style.backgroundColor
            },
        }
        store.commit("saveTextsHistoryState", { uniqueId, stateData });
    }
}

// 处理文本撤销
export function handleUndoText(selectedTextElement){
    // 获取选中元素的唯一id
    const uniqueId = selectedTextElement.id
    // 获取元素的父级元素，控制位置信息top、left
    const textParentElement = selectedTextElement.parentElement
    // 获取保存为位置的最后一个值
    const undoTextIndex = store.state.user.textsHistoryState[uniqueId].length - 1
    // 是否是否大于0
    if(undoTextIndex > 0){
        // 撤销回到倒数第二个位置 undoTextIndex-1
        const lastState = store.state.user.textsHistoryState[uniqueId][undoTextIndex - 1]
        // 删除上一个记录的位置信息
        store.state.user.textsHistoryState[uniqueId].splice(undoTextIndex)
        // 修改位置信息
        textParentElement.style.left = lastState.position.left
        textParentElement.style.top = lastState.position.top
        textParentElement.style.width = lastState.position.width
        // 修改样式
        for(let key in lastState.style){
            selectedTextElement.style[key] = lastState.style[key]
        }
    }
}
// 处理贴纸撤销
export function handleUndoSticker(selectedStickerElement){
    // 获取选中元素的唯一id
    const uniqueId = selectedStickerElement.id
    // 获取元素的父级元素，控制位置信息top、left
    const stickerParentElement = selectedStickerElement.parentElement
    // 获取贴纸img标签来改变大小
    const stickerImage = selectedStickerElement.querySelector("img")
     // 获取历史记录个数
    const undoStcikerIndex = store.state.user.stickersHistoryState[uniqueId].length - 1
    if(undoStcikerIndex > 0){
        const lastState =  store.state.user.stickersHistoryState[uniqueId][undoStcikerIndex - 1]
        // 清除上一个位置
        store.state.user.stickersHistoryState[uniqueId].splice(undoStcikerIndex)
        // 改变位置
        stickerParentElement.style.left = lastState.position.left
        stickerParentElement.style.top = lastState.position.top
        // 改变大小
        stickerImage.style.width = lastState.style.width
        stickerImage.style.height = lastState.style.height
    }
}


