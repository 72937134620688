<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div class="custom-preview">
    <div class="preview-content">
      <!-- 左侧内容 -->
      <div
        class="preview-content-left button-hover"
        @click="toTheLeft(selectPictureId)"
      >
        <el-icon style="height: 30px; width: 30px" v-if="!isFirstPicture"
          ><ArrowLeftBold />
        </el-icon>
      </div>
      <!-- 中间内容 -->
      <div class="preview-content-center">
        <div class="preview-content-center-top">
          <!-- 返回按钮 -->
          <div @click="goBack()" class="door" style="margin-left: 20px">
            <el-icon style="height: 15px; width: 15px"
              ><ArrowLeftBold
            /></el-icon>
            <span style="margin-left: 10px">返回</span>
          </div>
          <!-- 左侧菜单栏，编辑、下载、删除 -->
          <div class="doors-container">
            <div class="door" @click="editPicture()">
              <el-icon class="edit-icon" style="height: 15px; width: 15px"
                ><Edit
              /></el-icon>
              <span style="margin-left: 10px">编辑</span>
            </div>
            <div class="door" @click="deleteAiImage(selectPictureId)">
              <el-icon style="height: 15px; width: 15px"><Delete /></el-icon>
              <span style="margin-left: 10px">删除</span>
            </div>
            <div
              class="door"
              @click="downloadAiImage(selectPictureId, selectPictureUrl)"
            >
              <el-icon style="height: 15px; width: 15px"><Download /></el-icon>
              <span style="margin-left: 10px">下载</span>
            </div>
          </div>
        </div>
        <div class="preview-content-center-bottom">
          <div style="flex: 1; height: 100%"></div>
          <div class="preview-picture-container">
            <img
              :src="selectPictureUrl"
              alt=""
              style="width: 85%; height: auto"
            />
          </div>
          <div class="picture-info-container">
            <div class="info-item">
              <span>对应物品名称：</span>
              <p v-if="selectPictureAssetName">{{ selectPictureAssetName }}</p>
              <p v-else>该商品图已删除</p>
            </div>
            <div class="info-item">
              <span>主题名称：</span>
              <p>{{ selectPictureThemeName }}</p>
            </div>
            <div class="info-item">
              <span>图片大小：</span>
              <p>{{ selectPictureSize }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 右侧内容 -->
      <div
        class="preview-content-right button-hover"
        @click="toTheRight(selectPictureId)"
      >
        <el-icon style="height: 30px; width: 30px" v-if="!isLastPicture"
          ><ArrowRightBold
        /></el-icon>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { getPictures } from "@/api";
import {
  Download,
  Delete,
  ArrowLeftBold,
  ArrowRightBold,
  Edit,
} from "@element-plus/icons";
import { ElMessage, ElMessageBox } from "element-plus";
import { deletePicture } from "@/api";
import { HttpCodes } from "@/common/const";
export default {
  name: "picture-dialog-layer",
  components: {
    Download,
    Delete,
    ArrowLeftBold,
    ArrowRightBold,
    Edit,
  },
  data() {
    return {
      isFirstPicture: false,
      isLastPicture: false,
      pictures: [],
      isMessageVisible:false
    };
  },
  computed: {
    // 准备生成AI图的商品（vuex获取）
    selectPictureUrl() {
      return this.$store.state.user.selectPicture.url || "";
    },
    selectPictureName() {
      return this.$store.state.user.selectPicture.name || "";
    },
    selectPictureId() {
      return this.$store.state.user.selectPicture.picture_id || "";
    },
    selectPictureThemeName() {
      return this.$store.state.user.selectPicture.theme_name || "";
    },
    selectPictureAssetName() {
      return this.$store.state.user.selectPicture.asset_name || "";
    },

    selectPictureSize() {
      return this.$store.state.user.selectPicture.width + " × "  + this.$store.state.user.selectPicture.height
    },
  },
  methods: {
    // 删除按钮
    deleteAiImage(picture_id) {
      event.stopPropagation(); // 阻止事件冒泡
      ElMessageBox.confirm("删除之后，将无法恢复，是否确认删除？", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deletePicture(picture_id)
            .then((response) => {
              if (response.status == HttpCodes.SUCCESS) {
                ElMessage({
                  message: "删除成功",
                  type: "success",
                });
                this.$router.go(-1)
              }
            })
            .catch((error) => {
              console.error("Error deleting image:", error);
            });
        })
        .catch(() => {
          ElMessage({
            message: "已取消删除操作",
          });
        });
    },
    // 返回按钮
    goBack() {
      this.$store.commit("setSelectPicture", {});
      this.$router.push("/personal")
    },
    // 下载
    downloadAiImage(id, url) {
      if (id == "") {
        this.showMessage("请选择要下载照片", "warning");
        return;
      }
      const fileName = id + ".jpg";
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 编辑
    editPicture() {
      this.$router.push("/editpicture/text");
    },
    // 判断是否是第一个AI图
    isFirst(picture_id) {
      const index = this.pictures.findIndex(
        (item) => item.picture_id === picture_id
      );
      this.isFirstPicture = index === 0;
    },
    // 判断是否是最后一个AI图
    isLast(picture_id) {
      const index = this.pictures.findIndex(
        (item) => item.picture_id === picture_id
      );
      this.isLastPicture = index === this.pictures.length - 1;
    },
    // 下一个
    toTheRight(currentPictureId) {
      const pictures = this.pictures;
      const index = pictures.findIndex(
        (item) => item.picture_id === currentPictureId
      );
      if (index !== -1 && index < pictures.length - 1) {
        this.$store.commit("setSelectPicture", pictures[index + 1]);
        this.isFirstPicture = false;
      } else {
        this.showMessage("已经是最后一个了", "warning");
        this.isLastPicture = true;
      }
    },
    // 上一个
    toTheLeft(currentPictureId) {
      const pictures = this.pictures;
      const index = pictures.findIndex(
        (item) => item.picture_id === currentPictureId
      );
      if (index > 0) {
        this.$store.commit("setSelectPicture", pictures[index - 1]);
        this.isLastPicture = false;
      } else if (index === 0) {
        this.isFirstPicture = true;
        this.showMessage("已经是第一个了", "warning");
      }
    },
    showMessage(message, type) {
      if (this.isMessageVisible) return;
      this.isMessageVisible = true;

      ElMessage({
        message: message,
        type: type,
        plain: true,
        onClose: () => {
          this.isMessageVisible = false;
        }
      });
    },
    // 获取全部pictures
    getPicturesList() {
      getPictures()
        .then((response) => {
          if (response.status == HttpCodes.SUCCESS) {
            this.pictures = response.data.data.pictures;
            this.isFirst(this.selectPictureId);
            this.isLast(this.selectPictureId);
          }
        })
        .catch((error) => {
          console.error("Get pictures error!", error);
        });
    },
  },
  mounted() {
    // 判断是否有被选中的picture，没有话返回来时的路由
    if (Object.keys(this.$store.state.user.selectPicture).length === 0) {
      this.$router.go(-1);
    }
    this.getPicturesList();
  },
};
</script>
  
  <style>
/* 自定义全屏弹窗样式 */

.custom-preview {
  /* user-select设为none避免连续点击屏幕出现蓝色覆盖 */
  user-select: none;
}

/* 弹窗的内容区域 */
.custom-preview .preview-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: space-between; /* 左右两侧靠边，中间居中 */
}

/* 左侧内容 */
.preview-content-left {
  display: flex;
  flex-basis: 4%; /* 占左侧20%的宽度 */
  background-color: darkgray;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
}
.button-hover:hover {
  cursor: pointer;
  background-color: rgb(151, 150, 150);
}

/* 中间内容 */
.preview-content-center {
  flex-basis: 92%; /* 占中间60%的宽度 */
  text-align: center;
  height: 100%;
}
.preview-content-center .preview-content-center-top {
  display: flex;
  height: 7%;
  width: 100%;
  background-color: white;
  align-items: center;
  justify-content: space-between;
}
/* 中间内容的底部 */
.preview-content-center-bottom {
  width: 100%;
  flex-grow: 1; /* 使其填满剩余空间 */
  background-color: white;
  display: flex;
  box-sizing: border-box;
  overflow: auto; /* 允许内容滚动 */
  height: 93%;
  justify-content: center;
  align-items: center;
}

/* 右侧内容 */
.preview-content-right {
  flex-basis: 4%; /* 占右侧20%的宽度 */
  background-color: darkgray;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
}

.edit-icon {
  height: 20px;
  width: 20px;
}
.edit-icon:hover {
  cursor: pointer;
}

.doors-container {
  margin-right: 20px;
  display: flex;
  gap: 10px; /* 门之间的间距 */
}

.door {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(210, 63%, 97%);
  padding: 5px 10px;
  border-radius: 8px;
}
.door:hover {
  cursor: pointer;
}
.preview-picture-container {
  flex: 3;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.picture-info-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  padding-left: 20px;
}
.info-item {
  margin-bottom: 20px; 
}

.info-item span {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: large;
}

.info-item p {
  margin: 10px 0px; 
  color: rgb(68, 70, 71);
  max-width: 300px; 
  word-wrap: break-word;
  overflow-wrap: break-word; 
}
</style>