<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div>
    <div style="margin-top: 50px;width: 1600px;height:350px;display: flex;" v-if="showUploadAsset">
        <div class="upload-asset-container" @click="toUpload">
            <div class="upload-asset">
                <div class="icon-container">
                    <el-icon class="camera-icon"><Camera /></el-icon>
                    <el-icon class="add-icon"><CirclePlusFilled /></el-icon>
                </div>
            </div>
        </div>
    </div>
    <div style="margin-top: 50px;width: 1600px;height:350px;display: flex;" v-if="!showUploadAsset">
      <div style="display: flex;" v-for="asset in assets" :key="asset.asset_id" class="image-wrapper" @click="goToPreview(asset)">
        <img :src="asset.asset_url" width="300px" height="300px"/>
        <el-icon style="top: 8px; right: 8px;" class="icon" :id="'drop_' + asset.asset_id" @click="showPopup(asset.asset_id)"><More/></el-icon>
        <div class="modal" :id="'modal_' + asset.asset_id" v-if="modalVisible === asset.asset_id" @mouseleave="hidePopup(asset.asset_id)">
          <div class="modal-item" @click="downloadAssetImage(asset.asset_id,asset.asset_url)">
              <el-icon style="height: 20px;width: 20px"><Download/></el-icon>
              <span class="modal-item-text">下载</span>
          </div>
          <div class="modal-item">
              <el-icon style="height: 20px;width: 20px"><Male/></el-icon>
              <span class="modal-item-text">查看详情</span>
          </div>
          <div class="modal-item" @click="deleteProduct(asset.asset_id)">
              <el-icon style="height: 20px;width: 20px"><Delete/></el-icon>
              <span class="modal-item-text">删除</span>
          </div>
        </div>
        <el-icon style="top: 8px; right: 43px;" class="icon" @click="downloadAssetImage(asset.asset_id,asset.asset_url)"><Download/></el-icon>
        <i style="right: 3%; top: 81%;">
          <button class="preview-generate-button" @click="jumpToGenerate(asset)">创作</button>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import { Download ,More,Delete,Male,Camera,CirclePlusFilled } from "@element-plus/icons";
import {ElMessage, ElMessageBox} from "element-plus";
import { getAssets,deleteAsset} from "@/api";
import { HttpCodes } from "@/common/const";
export default {
  name: "product-float-layer",
  components: {
    Download,
    More,
    Delete,
    Male,
    Camera,
    CirclePlusFilled,
  },
  data(){
    return {
      showUploadAsset:false,
      modalVisible: false, // 初始时 modal 不可见
      // 测试数据
      assets:[],
      // showDialog:""
    }
  },
  computed:{
        // 准备生成AI图的商品（vuex获取）
        selectAssetUrl(){
            return this.$store.state.user.selectAsset.asset_url
        },
        selectAssetName(){
            return this.$store.state.user.selectAsset.name || "请选择商品图"
        },
        selectAssetId(){
            return this.$store.state.user.selectAsset.asset_id || ""
        },
        showDialog(){
          return this.$store.state.user.showDialog 
        }

    },
  methods: {
    // 下载原商品提取图或者已经生成好的商品展示图
    downloadAssetImage(id,url) {
      event.stopPropagation(); // 阻止事件冒泡
      const fileName = id + ".jpg"
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    deleteProduct(asset_id){
      event.stopPropagation(); // 阻止事件冒泡
      ElMessageBox.confirm(
        '删除之后，将无法恢复，是否确认删除？',
        'Warning',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          lockScroll:false
        }
      ).then(() => {
        deleteAsset(asset_id)
          .then(response => {
            if(response.status == HttpCodes.SUCCESS){
              ElMessage({
                message:"删除成功",
                type:"success"
              })
              // 获取新的前四个商品图
              this.getAssetsList()
            }else{
              // 删除错误的提示
              console.log(response)
            }
            })
            .catch(error => {
              console.error('Error deleting image:', error);
            });
        })
        .catch(() => {
          ElMessage({
            message:"已取消删除操作"
          })
      })
    },
    jumpToGenerate(asset) {
      event.stopPropagation(); // 阻止事件冒泡
      this.$store.commit("setSelectAsset", asset)
      if(this.showDialog){
        this.goBack()
        this.showDialog = false
      }
      this.$router.push("/generate")
    },
    showPopup(itemId) {
      event.stopPropagation(); // 阻止事件冒泡
      // 点击展示弹出框的方法
      if (this.modalVisible === itemId) {
        this.modalVisible = null; // 如果当前点击的和之前的相同，则关闭弹出框
      } else {
        this.modalVisible = itemId; // 否则打开对应的弹出框
      }
    },
    hidePopup() {
      this.modalVisible = null;
    },
    getAssetsList(){
      getAssets({
        page:1,
        page_size:4
      }).then(response => {
        if(response.status == HttpCodes.SUCCESS){
          this.assets = response.data.data.assets
          // 返回assets的长度为0显示站位
          if(this.assets.length == 0){
            this.showUploadAsset = true
            // 不显示站位
          }else{
            this.showUploadAsset = false
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 修改vuex中全屏弹出框的展示条件
    goToPreview(asset){
      this.$store.commit("setSelectAsset", asset)
      this.$router.push("/assetpreview")
    },
    toUpload(){
      this.$router.push("upload/")
    }
  },
  mounted(){
    this.getAssetsList()
  }
};
</script>

<style>
.upload-asset-container{
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  position: relative;
}
.upload-asset{
  display: flex;
  width: 300px;
  height: 300px;
  background-color: #e4e4e6;
  justify-content: center;
  align-items: center;
}

.upload-asset:hover{
  cursor: pointer;
  background-color: #dee2e6;
}
.icon-container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9999;
}
.camera-icon{
  height: 110px;
  width: 110px;
  color: rgb(190, 186, 186);
}
.add-icon{
  height: 30px;
  width: 30px;
  color: green;
  position: absolute;
  right: 1px;
  bottom: 1px;
}


.image-wrapper {
  /* margin: auto !important; */
  margin-left: 50px;
  margin-right: 50px;
  position: relative;
  height: 300px;
  width: 300px;
}
.image-wrapper img {
  width: 300px;
  height: 300px;
}

.image-wrapper:hover {
  cursor: pointer;
}

.image-wrapper:hover::after {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  height: 300px;
  top: 0;
  width: 300px;
  z-index: 99;
}
.icon {
  padding: 5px;
  display: none;
  position: absolute;
  height: 20px !important;
  width: 20px !important;
  background-color: white;
  border-radius: 5px;
  color: white;
}

.image-wrapper:hover .icon {
  display: block;
  z-index: 999;
}

.image-wrapper i {
  display: none;
}

.image-wrapper:hover i {
  display: inline-block;
  position: absolute;
  font-size: 40px;
  z-index: 999;
  color: #fff;
  top: 50%;
}

.preview-generate-button {
  width: 80px;
  height: 30px;
  color: black;
  background-color: orange;
  border-radius: 3px;
  font-size: 17px;
  font-style: normal;
  border: None;
  cursor: pointer;
}
.modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  right: 8px;
  border: 1px solid black;
  background-color: white;
  z-index: 999;
  border-radius: 5px;
}
.modal-item{
  display: flex;
  padding: 10px;
  font-weight: bold;
}
.modal-item:hover {
  background-color: lightgray;
}
.modal-item-text{
  margin-left: 10px
}

</style>