<template>
  <div class="navigator-text-container">
      <div class="navigator-text-top">
          <div 
              class="navigator-text-top-btn" 
              :class="{ 'selected': selectedIndex === 'text' }"
              @click="selectItem('text')">
              <i class="iconfont icon-text" style="font-weight: bold;font-size: 20px"></i>
              <span>文字</span>
          </div>
          <div 
              class="navigator-text-top-btn"
              :class="{ 'selected': selectedIndex === 'sticker' }"
              @click="selectItem('sticker')">
              <i class="iconfont icon-tiezhi" style="font-weight: bold;font-size: 20px;margin-right: 5px;"></i>
              <span>贴纸</span>
          </div>
      </div>
      <div class="boundary"></div>
      <div class="navigator-text-content">
        <div class="text-content-container">
          <h3>文本内容</h3>
          <div class="text-input-container">
            <el-input 
              size="large" 
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              v-model="textStyle.textContent"
              @input="updateTextStyle" 
            ></el-input>
          </div>
        </div>
        <div class="text-style-container">
          <h3>文本样式</h3>
          <!-- 文本样式选择器 -->
          <div>
            <el-select
              placeholder="Select"
              size="large"
              style="width: 100%"
              v-model="textStyle.fontFamily"
              @change="updateTextStyle"
              >
              <el-option
                v-for="item in fontFamilysOptions"
                :key="item"
                :label="item"
                :value="item"
                :style="{ fontFamily: item + '-Regular'}"
              />
            </el-select>
          </div>
          <!-- 文本粗细选择器 -->
          <div class="select-bold-class">
            <div style="flex: 1;">
              <el-select
                placeholder="Select"
                size="large"
                style="width: 100%"
                v-model="textStyle.fontWeight"
                @change="updateTextStyle"
                >
                <el-option
                  v-for="item in fontWeights"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :style="{ fontWeight: item.fontWeight}"
                />
              </el-select>
            </div>
            <div style="flex: 1;margin-left: 10px">
              <el-input-number @change="updateTextStyle" size="large" v-model="textStyle.fontSize"/>
            </div>
          </div>
          <!-- 布局 -->
          <div class="select-layout-class">
            <!-- 布局方式 -->
            <div style="flex: 2;">
              <!-- 改用多选框显示每次选择只能选一个，可取消选择 -->
              <el-checkbox-group v-model="checkTextAlignList" size="large" class="my-el-radio-style" @change="changeLayout">
                <el-checkbox-button 
                  v-for="item in layout" 
                  :key="item.label" 
                  :label="item.label" 
                  :value="item.value"
                  >
                  <i :class="['iconfont', item.icon]" style="font-weight: bolder;"></i>
                </el-checkbox-button>
              </el-checkbox-group>
            </div>

            <div style="flex: 1;display: flex;justify-content: right">
              <!-- 改用多选框显示每次选择只能选一个，可取消选择 -->
              <el-checkbox-group 
                v-model="checkTextDecorationList"
                size="large" 
                class="my-el-radio-style"
                @change="changeTextDecoration">
                <el-checkbox-button 
                  v-for="item in UnderlineOrStrikethrough" 
                  :key="item.label" 
                  :label="item.label" 
                  :value="item.value">
                  <i :class="['iconfont', item.icon]" style="font-weight: bolder;"></i>
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
          </div>
          <!-- 间距 -->
          <div class="select-gap-class">
            <!-- 行间距 -->
            <div class="line-gap-class">
              <h4>行间距</h4>
              <el-input size="large" v-model="textStyle.lineHeight" @input="updateTextStyle"/>
            </div>
            <!-- 字间距 -->
            <div class="letter-spacing-class">
              <h4>字间距</h4>
              <el-input  size="large" v-model="textStyle.letterSpacing" @input="updateTextStyle"/>
            </div>
          </div>
          <!-- 颜色 -->
          <div style="margin-top: 20px;">
            <h4 style="margin: 0;margin-bottom: 5px">颜色</h4>
            <div class="select-color-class">
              <div class="text-color" style="margin-right: 5px;">
                <span>文字颜色</span>
                <color-picker ref="fontColorPicker" :left="fontColorPickerleft" :width="fontColorPickerWidth" :selectedColor="textStyle.color" @update:selectedColor="updateTextColor" />
              </div>
              <div class="text-color" style="margin-left: 5px;">
                <span>文字底色</span>
                <color-picker ref="backgroundColorPicker" :left="backgroungColorPickerLeft" :width="backgroungColorPickerWidth" :selectedColor="textStyle.backgroundColor" @update:selectedColor="updateBackgroundColor" />
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px;display: flex;justify-content: right;">
          <el-button type="info" plain @click="createHtmlElement" size="large">添加文本</el-button>
        </div>
      </div>
  </div>
</template>

<script>
import { ElInput,ElSelect,ElOption,ElInputNumber,ElButton,ElCheckboxGroup,ElCheckboxButton } from 'element-plus';
import { addElementDragEvent , setSelectedElementStyle, createElementRecord ,saveHistoryState} from '@/utils/commonFunctions';
import ColorPicker from '../ColorComponent/ColorPicker.vue';
import { getFonts } from '@/api';
export default {
    name: "navigator-text",
    components:{
      ElInput,
      ElSelect,
      ElOption,
      ElInputNumber,
      ColorPicker,
      ElButton,
      ElCheckboxGroup,
      ElCheckboxButton,

    },
    data() {
        return {
          checkTextDecorationList:[],
          checkTextAlignList:[],
          // 选择器弹出的位置
          fontColorPickerleft:"-6.5vw",
          fontColorPickerWidth:"17vw",
          backgroungColorPickerWidth:"17vw",
          backgroungColorPickerLeft:"-16vw",
          selectedIndex:'text',
          // 字体样式选择
          fontFamilys: [],
          fontFamilysOptions:[],
          // 是否加粗选择
          fontWeights:[
            {
              value:"normal",
              label:"Regular",
              fontWeight:"normal"
            },
            {
              value:"bold",
              label:"Bold",
              fontWeight:"700"
            },
            {
              value:"bolder",
              label:"UltraBold",
              fontWeight:"900"
            }
          ],
          textStyle:{
            // 内容
            textContent:"text",
            // 字体样式
            fontFamily:"ORGANETTO",
            // 是否加粗
            fontWeight:"normal",
            // 布局
            textAlign:"left",
            // 下划线或者删除线
            textDecoration:"",
            // 字体大小
            fontSize:24,
            // 行间距
            lineHeight:24,
            // 字间距
            letterSpacing:1.5,
            // 字体颜色
            color:"rgba(0, 0, 0, 1)",
            // 字体底色
            backgroundColor:"rgba(255, 0, 0, 0)",

          },
          // 文字布局方式
          layout:[ 
            { label:"1",value:"left" ,icon:"icon-zuoduiqi"}, // 左对齐
            { label:"2",value:"right" ,icon:"icon-youduiqi"}, // 右对齐
            { label:"1",value:"center",icon:"icon-juzhongduiqi" }, // 居中
            { label:"1",value:"justify",icon:"icon-liangduanduiqimohangzuoduiqi" }, // 两端对我
          ],
          // 下划线删除线
          UnderlineOrStrikethrough:[
            // 下划线
            { label:1 , value:"underline",icon:"icon-xiahuaxian"},
            // 删除线
            { label:2 , value:"line-through",icon:"icon-shanchuxian"},
          ],
          // 预定义颜色
          predefineColors:[
            '#ff4500',
            '#ff8c00',
            '#ffd700',
            '#90ee90',
            '#00ced1',
            '#1e90ff',
            '#c71585',
            'rgba(255, 69, 0, 0.68)',
            'rgb(255, 120, 0)',
            'hsv(51, 100, 98)',
            'hsva(120, 40, 94, 0.5)',
            'hsl(181, 100%, 37%)',
            'hsla(209, 100%, 56%, 0.73)',
            '#c7158577',
            '#FFFFFF'
          ],
          selectedTextId:null,
          startX:null,
          startY:null,
          aspectRatio:null
        }
    },
    methods: {
      updateBackgroundColor(newColor) {
        this.textStyle.backgroundColor = newColor;  // 更新父组件中的颜色
        this.updateTextStyle()
      },
      updateTextColor(newColor){
        this.textStyle.color = newColor
        this.updateTextStyle()
      },
      selectItem(item){
        if(item == "text"){
          return
        }
        this.$router.push("/generate/sticker")
      },
      // 改变布局方式 左对齐、右对齐、居中
      changeLayout(){
        this.checkTextAlignList = this.checkTextAlignList.slice(this.checkTextAlignList.length -1)
        this.textStyle.textAlign = this.checkTextAlignList[0]
        this.updateTextStyle()
      },
      // 改变是否有下划线或者删除线
      changeTextDecoration(){
        this.textStyle.textDecoration = ""
        this.checkTextDecorationList = this.checkTextDecorationList.slice(this.checkTextDecorationList.length -1)
        if(this.checkTextDecorationList.length > 0){
          this.textStyle.textDecoration = this.checkTextDecorationList[0]
        }
        this.updateTextStyle()
      },

      // 更新字体样式
      updateTextStyle(){
        // 行间距和字体大小一致
        this.textStyle.lineHeight = this.textStyle.fontSize
        const container = document.getElementById("container")
        const text = container.querySelector(".DT-text-container.selected")
        if(text){
          const div = text.parentElement
          saveHistoryState(div)
          this.setTextStyle(null,text.id)
        }
      },
      
      setTextStyle(element = null, textId = null) {
        if (!element) {
          // 如果没有传入 element，则通过 textId 获取元素
          element = document.getElementById(textId);
        }

        // 如果 element 不存在，直接返回
        if (!element) {
          console.warn("Element not found.");
          return;
        }
        const pTag = element.querySelector("p")
        pTag.textContent = this.textStyle.textContent
        // 遍历 textStyle 对象，应用样式
        for (let key in this.textStyle) {
          if (Object.prototype.hasOwnProperty.call(this.textStyle, key)) {
            let value = this.textStyle[key];
            // 处理 fontSize、lineHeight、letterSpacing 的单位
            if (key === "fontSize" || key === "lineHeight" || key === "letterSpacing") {
              element.style[key] = value + "px";
            } else {
              element.style[key] = value;
            }
            if (key === "fontFamily"){
              // 组装fontFamily字符串
              const font_family = this.textStyle.fontFamily
              const font_weight = this.textStyle.fontWeight
              const weightMap = {
                'normal':"Regular",
                'bold':"Bold",
                'bolder':'UltraBold',
              }
              element.style[key] = font_family + "-" + weightMap[font_weight] || 'Regular'
            }
          }
        }
      },
      //节流函数，限制缩放触发次数，让字体缩放变慢
      throttle(fn, wait) {
        let lastTime = 0;
        return function(...args) {
          const now = Date.now();
          if (now - lastTime >= wait) {
            fn.apply(this, args);
            lastTime = now;
          }
        };
      },
      // 创建文本框，并且添加拖拽移动和缩放事件
      createHtmlElement(left="0px", top="0px",uniqueId=null,width=null) {
        const container = document.getElementById('container');
        const div = document.createElement('div');
        div.classList.add('text-container');

        const textContainerDiv = document.createElement('div');
        textContainerDiv.classList.add('DT-text-container');
        const newElementP = document.createElement("p")
        newElementP.style.margin = 0
        textContainerDiv.appendChild(newElementP)
        this.setTextStyle(textContainerDiv);  // 设置 p 标签的样式

        const textPointClasses = ["text-top-left", "text-top-right", "text-bottom-left", "text-bottom-right",'text-left','text-right'];
        textPointClasses.forEach(positionClass => {
          const textDianDiv = document.createElement('div');
          if(positionClass != 'text-left' && positionClass != 'text-right'){
            textDianDiv.classList.add("text-dian", positionClass);
          }else{
            textDianDiv.classList.add('scale-width-dian',positionClass);
          }
          textContainerDiv.appendChild(textDianDiv);
          textDianDiv.addEventListener("mousedown", (e) => {
            e.stopPropagation();
            // 开始位置坐标
            this.startX = e.clientX;
            this.startY = e.clientY;

            this.aspectRatio = div.getBoundingClientRect().width / div.getBoundingClientRect().height

            const initialX = e.clientX
            const initialy = e.clientY
            // 初始容器的left和top
            const initialLeft = parseInt(div.style.left) || 0;
            const initialTop = parseInt(div.style.top) || 0;
            // 获取初始div的width
            const initDivWidth = div.getBoundingClientRect().width
            const initDivHeight = div.getBoundingClientRect().height
            const inintFontSize = parseInt(textContainerDiv.style.fontSize)
            // 字体大小和容器高度比例 让字体大小适应容器高度的大小
            const FontSizeHeightRatio  = initDivHeight / inintFontSize

            const mouseMoveHandler = this.throttle((e) => {
              e.stopPropagation();
              
              // const changeFontsize = e.clientY - this.startY;

              const deltaX = e.clientX - initialX
              const deltaY = e.clientY - initialy

              // 更新字体大小和行高
              const updateFontSize = (newFontSize) => {
                textContainerDiv.style.fontSize = `${newFontSize}px`;
                textContainerDiv.style.lineHeight = `${newFontSize}px`;
                this.textStyle.fontSize = newFontSize
                this.textStyle.lineHeight = newFontSize
              };
              // 动态调整宽度和字体大小
              if (positionClass === "text-top-left") {
                div.style.top = `${initialTop + deltaY}px`;
                div.style.left = `${initialLeft + deltaY * this.aspectRatio}px`;
                // div.style.height = `${initDivHeight - deltaY}px`;
                div.style.width = `${(initDivHeight - deltaY) * this.aspectRatio}px`;
                const newFontSize = parseInt((initDivHeight - deltaY) / FontSizeHeightRatio)
                updateFontSize(newFontSize)
              }
              if (positionClass === "text-top-right"){
                div.style.top = `${initialTop + deltaY}px`;
                // div.style.height = `${initDivHeight - deltaY}px`;
                div.style.width = `${(initDivHeight - deltaY) * this.aspectRatio}px`;
                const newFontSize = parseInt((initDivHeight - deltaY) / FontSizeHeightRatio)
                updateFontSize(newFontSize)
              }
              if (positionClass === "text-bottom-left"){
                div.style.left = `${(initialLeft - deltaY) * this.aspectRatio}px`;
                // div.style.height = `${initDivHeight - deltaY}px`;
                div.style.width = `${(initDivHeight + deltaY) * this.aspectRatio}px`;
                const newFontSize = parseInt((initDivHeight + deltaY) / FontSizeHeightRatio)
                updateFontSize(newFontSize)
              }
              if (positionClass === "text-bottom-right"){
                // div.style.height = `${initDivHeight + deltaY}px`;
                div.style.width = `${(initDivHeight + deltaY) * this.aspectRatio}px`;
                const newFontSize = parseInt((initDivHeight + deltaY) / FontSizeHeightRatio)
                updateFontSize(newFontSize)
              }
              // 缩放文本框的宽度
              if (positionClass === 'text-left'){
                div.style.left = `${initialLeft + deltaX}px`;
                div.style.width = `${initDivWidth - deltaX}px`;
                // newElementP.style.width = `${initDivWidth - deltaX}px`;
              }
              if (positionClass === 'text-right'){
                div.style.width = `${initDivWidth + deltaX}px`;
              }
              this.startX = e.clientX
              this.startY = e.clientY
            }, 30); // 每 30ms 执行一次

            const mouseUpHandler = (e) => {
              saveHistoryState(div)
              e.stopPropagation()
              document.removeEventListener("mousemove", mouseMoveHandler);
              document.removeEventListener("mouseup", mouseUpHandler);
            };

            document.addEventListener("mousemove", mouseMoveHandler);
            document.addEventListener("mouseup", mouseUpHandler);
          });
        });
        div.appendChild(textContainerDiv);
        div.style.left = left
        div.style.top = top
        if(width){
          div.style.width = width
        }
        container.appendChild(div);
        if(!uniqueId){
          uniqueId = `text-container-${Date.now()}`;
        }

        textContainerDiv.id = uniqueId;
        textContainerDiv.addEventListener('click', () => this.selectTextDivElement(uniqueId));
        // 创建文本默认选中
        this.selectTextDivElement(uniqueId)
        // 添加拖动事件
        addElementDragEvent(div);
        createElementRecord(uniqueId)
        // 保存初始位置
        saveHistoryState(div)
      },
      setTextStyleFromElement(element) {
        // css字体样式中的Regular为normal
        const weightMap = {
          'Regular':"normal",
          'Bold':"bold",
          'UltraBold':'bolder',
        }
        const updatedStyle = {
          fontFamily: element.style.fontFamily.split('-')[0] || "",
          fontWeight: weightMap[element.style.fontFamily.split('-')[1]]|| "normal",
          textAlign: element.style.textAlign || "",
          textDecoration: element.style.textDecoration || "",
          fontSize: parseInt(element.style.fontSize, 10) || 0,  
          lineHeight: parseInt(element.style.lineHeight, 10) || 0, 
          letterSpacing: parseFloat(element.style.letterSpacing) || 0,
          color: element.style.color || "",
          backgroundColor: element.style.backgroundColor || "",
          textContent: element.textContent || ""
        };
        this.textStyle = updatedStyle
      },
      // 选中的文本框高亮显示
      selectTextDivElement(textId) {
        // 调用设置选中的样式显示边框
        this.selectedTextId = textId
        setSelectedElementStyle(textId,"container")
        const element = document.getElementById(textId)
        // 获取文本框的p标签
        if (element) {
          this.setTextStyleFromElement(element);
        } 
      },
      // 重置字体 【导航到其它页面再回来vue双向绑定会失效，需要删除原来字体，创建一个新的，位置样式不变】
      resetText(){
        // 获取透明背景容器
        const container = document.getElementById("container")
        // 获取文本框
        const texts = container.querySelectorAll(".text-container")
        // 循环文本框
        texts.forEach((textElement) => {
          const textContent = textElement.querySelector(".DT-text-container")
          this.setTextStyleFromElement(textContent)
          textElement.remove()
          const uniqueId = textContent.id
          // 创建文本内容，传入位置信息、宽度、如果不传宽度默认是一行文本
          this.createHtmlElement(textElement.style.left, textElement.style.top,uniqueId, textElement.style.width)
        })
      },
      getFontList(){
        getFonts().then(response => {
          this.fontFamilysOptions = response.data.data.font_family
          this.fontFamilys = response.data.data.font_family
          var style = document.createElement('style');
          style.type = 'text/css';
          this.fontFamilys.forEach(item => {
            var fontFace = `
              @font-face {
                font-family: '${item.label}';
                src: url('${item.value}') format('woff2'),
                font-weight: normal;
                font-style: normal;
                font-display: swap;
              }`
            style.appendChild(document.createTextNode(fontFace));
          })
          // 筛选出有几种字体样式
          this.fontFamilysOptions = [...new Set(this.fontFamilysOptions.map(font => font.font_family))];
          document.head.appendChild(style);
        }).catch(error => {
          console.log("Get fonts error:",error)
        })
      },
      
    },
    mounted(){
      this.getFontList()
      this.resetText()
    },
};
</script>

<style>

.text-container{
    position: absolute;
    max-width: 600px;
}


.DT-text-container{
    cursor: pointer;
    position: relative;
    border: 2px dashed  transparent;
    word-break:break-all;
    box-sizing: content-box;
    white-space: pre-line;
}
.DT-text-container:hover {
    border-color: var(--el-color-primary);
}
.DT-text-container:hover .text-dian {
    display: block;
}
.DT-text-container:hover .scale-width-dian {
    display: block;
}
/* 选中的文本框 */
.DT-text-container.selected {
    border-color: var(--el-color-primary);;
}
.DT-text-container.selected .text-dian{
    display: block;
}
.DT-text-container.selected .scale-width-dian{
  display: block;
}
/* 文本框顶点 */
.text-dian {
    /* display: none; */
    position: absolute;
    background-color:  white;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    border: 1px solid black;
}
.scale-width-dian{
  display: none;
  position: absolute;
  height: 12px;
  width: 6px;
  background-color: white;
  border: 1px solid black;
  border-radius: 30%;
  top: 50%;
  transform: translateY(-50%);
}
.text-left{
  left: -5px;
  cursor: ew-resize;
}
.text-right{
  right: -5px;
  cursor: ew-resize;
}

.text-top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize;
}

.text-top-right {
    right: -5px;
    top: -5px;
    cursor: ne-resize;
}

.text-bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}

.text-bottom-right {
    right: -5px;
    bottom: -5px;
    cursor: nwse-resize;
}

.text-dian:hover {
    transform: scale(1.5);
}
/*  */



.text-icon-style{
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.navigator-text-container{
  width: 83%; 
  max-height: 100%; 
  display: flex; 
  flex-direction: column;
}
.navigator-text-top{
  height: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
}
.navigator-text-top-btn{
  display: flex;
  padding: 8px 20px;
  /* background-color: rgb(242, 191, 25); */
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgb(242, 191, 25);
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}
.navigator-text-top-btn.selected {
  background-color: rgb(242, 191, 25);;
}
.navigator-text-content{
  width: 100%;
  height: 95%;
  background-color: rgb(246, 248, 250);
}

.navigator-text-content{
  background-color: white;
  width: 100%;
  height: 60%;
  margin-top: 10px;

}

.text-content-container{
  display: flex;
  flex-direction: column;
  text-align: left;
}
.text-content-container h3{
  margin: 0;
}
.select-bold-class{
  display: flex;
  margin-top: 10px
}
.select-layout-class{
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
}
.text-input-container{
  margin-top: 20px;
  max-height: 200px;
}
.text-style-container{
  display: flex;
  flex-direction: column;
  text-align: left;
}
/* 重写element-plus样式 */
.my-el-radio-style .el-checkbox-button--large .el-checkbox-button__inner{
  padding: 12px 15px !important;
}
.my-el-radio-style .el-checkbox-button{
  --el-checkbox-button-checked-bg-color: #d2cfce !important;
}
.el-checkbox-button.is-focus .el-checkbox-button__inner{
  border-color:#d2cfce !important;
}
.el-checkbox-button.is-checked .el-checkbox-button__inner{
  border-color:#d2cfce !important;
  box-shadow:none !important
}


.select-gap-class{
  display: flex;
  margin-top: 20px;
}
.select-gap-class h4{
  margin: 0;
  margin-bottom: 5px;
}

.line-gap-class{
  flex: 1;
  margin-right: 5px;
}
.letter-spacing-class{
  flex: 1;
  margin-left: 5px
}
.select-color-class{
  display: flex;
  justify-content: space-around;
}
.text-color{
  flex: 1;
  box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
  border-radius: var(--el-input-border-radius,var(--el-border-radius-base));
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding: 5px 10px;
  box-sizing: border-box;
}

.color-block{
  height: 30px;
  width: 30px;
  background-color: antiquewhite;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

</style>