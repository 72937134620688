<template>
    <div style="display: flex;">
        <!-- 显示二维码 -->
        <div class="pay_box" v-if="show_pay">
            <div> 
              <p>交易将在 <span style="color: blue;">{{ minutes }}:{{ seconds }}</span> 后关闭，请及时付款！</p>
            </div>
            <p>请扫描下方二维码完成订单支付</p>
            <!-- 使用 canvas 元素作为二维码的容器 -->
            <canvas id="qrcode"></canvas>
            <div class="btn_box">
                <el-button type="info" @click="goBack">返回</el-button>
                <el-button type="primary" @click="finishPay">我已经完成支付</el-button>
            </div>
        </div>
        <!-- 显示支付成功 -->
        <div style="margin:150px auto" v-if="show_success">
            <el-result
                icon="success"
                title="支付成功">
                <template #extra>
                    <el-button type="primary" @click="goToOrderDetail()">查看详情</el-button>
                </template>
            </el-result>
        </div>
        <!-- 显示支付超时 -->
        <div style="margin:150px auto" v-if="isTimeout">
            <el-result
                icon="warning"
                title="支付超时"
                sub-title="抱歉，你的支付已超时，请返回充值页面重新发起!">
                <template #extra>
                    <el-button type="primary" @click="toPricing">返回</el-button>
                </template>
            </el-result>
        </div>
    </div>
</template>

<script>
import QRCode from 'qrcode';
import { updateOrder } from '@/api';
import { HttpCodes } from '@/common/const';
import { ElResult, ElButton } from 'element-plus';
export default {
    components:{
        ElResult,
        ElButton
    },
    name: "PayPage",
    data() {
        return { 
            show_pay: true,  // 是否展示支付二维码
            show_success:false,
            paymentUrl: '',  // 二维码url
            order_sn:"",     // 订单号
            interval:"",     // 轮询订单状态定时器
            isTimeout:false, // 超时显示 
            timeout: 600,    // 超时时间
            timeout_interval:"",
            order:{},
        };
    },
    mounted() {
        // 页面加载监听刷新事件
        window.addEventListener('beforeunload', this.handleRefresh);
        // 获取支付的二维链接，在创建订单成功之后后端返回的code_url先保存在localStorage
        if (JSON.parse(localStorage.getItem("order"))) {
            this.paymentUrl = JSON.parse(localStorage.getItem("order")).code_url;
            this.order_sn = JSON.parse(localStorage.getItem("order")).out_trade_no
            this.generateQRCode()
            this.orderPoll()
        } else {
            this.$router.go(-1)
            this.paymentUrl = ""
        }

    },
    beforeUnmount() {
        // 组件卸载的时候移除事件
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    },
    computed:{
        // 计算分钟
        minutes() {
            return Math.floor(this.timeout / 60);
        },
        // 计算秒
        seconds() {
            return this.timeout % 60 < 10 ? '0' + this.timeout % 60 : this.timeout % 60;
        },
    },
    methods: {
        goBack() {
            this.$router.push("/pricing");
        },
        // 检验是否是否完成支付
        finishPay() {
            if(this.show_success){
                this.show_pay = false
            }
        },
        // 生成支付二维码
        generateQRCode() {
            const canvasEl = document.getElementById('qrcode');
            if (canvasEl) {
                QRCode.toCanvas(canvasEl, this.paymentUrl, { width: 300 }, (error) => {
                    if (error) {
                        console.log(error)
                    } else {
                        // 生成支付二维码后开始十分钟倒计时
                        this.startCountdown()
                    }
                });
            } else {
                console.error('二维码容器未找到');
            }
        },
        // 轮询查看订单状态
        orderPoll() {
            this.interval = setInterval(() => {
                // isTimeout为未超时
                updateOrder(this.order_sn,{isTimeout:"0"}).then(response => {
                    if(response.status == HttpCodes.SUCCESS){
                        const user_update_data = response.data.data.user_data
                        this.order = response.data.data.order_data
                        const userInfo = JSON.parse(localStorage.getItem("userInfo"))
                        Object.entries(user_update_data).forEach(([key, value]) => {
                            userInfo[key] = value
                        });
                        localStorage.setItem("userInfo",JSON.stringify(userInfo))
                        this.show_success = true
                        this.show_pay = false
                        localStorage.removeItem("order")
                        // 清除订单轮询定时器
                        clearInterval(this.interval)
                        // 清除十分倒计时定时器
                        clearInterval(this.timeout_interval)
                    }else{
                        // 轮询返回的状态码不是200继续轮询
                        return
                    }
                }).catch(error => {
                    console.log(error)
                })
            }, 5000);
        },
        toPricing(){
            this.$router.push("/pricing")
        },
        startCountdown() {
            this.timeout_interval = setInterval(() => {
                if (this.timeout > 0) {
                    this.timeout--;
                } else {
                    clearInterval(this.interval);
                    clearInterval(this.timeout_interval)
                    this.timeout = 0;
                    // 在倒计时结束的时候发一次更新订单请求,携带isTimeout为1，已超时
                    updateOrder(this.order_sn,{isTimeout:"1"}).then(response => {
                        if(response.status == HttpCodes.SUCCESS){
                            // 清除存在本地的order信息
                            localStorage.removeItem("order")
                            // 显示超时提醒
                            this.isTimeout = true
                            // 重置二维码paymentUrl
                            this.paymentUrl = ""
                            // 取消显示支付二维码
                            this.show_pay = false
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            }, 1000);
        },
        goToOrderDetail(){
            this.$store.commit("setSelectOrder",this.order)
            this.$router.push('/personal/order/detail')
        },
        // 监听刷新事件
        handleRefresh() {
            // 移除订单
            localStorage.removeItem("order")
            // 回到支付页面
            this.$router.go(-1)
        },
    },
}
</script>

<style>
.pay_box {
    margin: 100px auto;
}
.pay_box img {
    width: 300px;
    height: auto;
}
.btn_box {
    display: flex;
    justify-content: center;
}
.btn {
    cursor: pointer;
    transition: background-color 0.3s ease; /* 添加过渡效果，使颜色变化更平滑 */
}
.back_btn {
    border: 1px solid black;
    padding: 5px 20px;
    margin-right: 40px;
}
.success_btn {
    background-color: blue;
    padding: 5px 10px;
}
.btn:hover {
    background-color: lightblue; /* 鼠标悬停时的背景色 */
    color: white; /* 文本颜色变为白色 */
}
</style>
