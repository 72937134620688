<template>
    <div class="edit-page-container">
        <div class="edit-page-menu-container">
            <div class="edit-navigator">
                <div v-for="(item, index) in items" :key="index" class="navigator-item" @click="setActiveMenu(item.route)">
                    <div :class="[{ 'active': activeIndex === item.route }]">
                        <i 
                            :class="[item.icon, 'iconfont' ,{'active-color':activeIndex === item.route}]" 
                            style="font-weight: bold; font-size: 20px;">
                        </i>
                        <span 
                            :class="[{'active-color':activeIndex === item.route}]">
                            {{ item.text }}
                        </span>
                    </div>
                </div>
            </div>

            <el-divider border-style="double" direction="vertical" style="height: 100%;margin: 0px;"/>
            <!-- 子路由 -->
            <div style="width: 83%;">
                <router-view></router-view>
            </div>
        </div>
        <el-divider border-style="double" direction="vertical" style="height: 100%;margin: 0;"/>
        <!-- 主编辑区 -->
        <div class="edit-main-container">
            <div
                id="themeContainer"
                ref="themeContainer"
                @wheel.prevent="onZoom"
                :style="{ backgroundImage: `url(${themeUrl})` }"
                >
                <div @mousedown="startDrag" 
                    ref="photo" 
                    id="photo" 
                    :style="{
                        position:'relative',
                        width:photoSize.width + 'px',
                        height:photoSize.height + 'px',
                        left: photoPosition.left + 'px',
                        top: photoPosition.top + 'px',
                        }"
                    @click="selectAssetClick('photo')">
                    <img
                        ref=""
                        :src="transparent_background_url"
                        :style="{
                            // left: photoPosition.left + 'px',
                            // top: photoPosition.top + 'px',
                            width: photoSize.width + 'px',
                            height: photoSize.height + 'px',
                            // position: 'absolute',
                            // objectFit: 'contain',
                        }"/>
                    <div class="dian top-left" @mousedown="startResize($event, 'top-left')"></div>
                    <div class="dian top-right" @mousedown="startResize($event, 'top-right')"></div>
                    <div class="dian bottom-left" @mousedown="startResize($event, 'bottom-left')"></div>
                    <div class="dian bottom-right" @mousedown="startResize($event, 'bottom-right')"></div>
                </div>
            </div>
        </div>
        <div class="btn-container">
            <div style="display: flex;;position: absolute;top: 20px;right: 20px;">
                <el-icon style="height: 50px;width: 50px;" @click="cancelUpdatePicture"><Back /></el-icon>
            </div>
            <div style="display: flex;margin-bottom: 20px;margin-right: 50px;">
                <span 
                    :aria-disabled="isUpdating"
                    :class="{ 'disabled': isUpdating }"
                    @click="updatePictue"
                    >确认
                </span>
                <span style="background-color: darkgray;" @click="undoEditPicture">撤销</span>
            </div>
        </div>
    </div>
  </template>
  
<script>
import { ElLoading,ElMessage,ElDivider } from 'element-plus';
import { updataPicture } from '@/api';
import { HttpCodes } from '@/common/const';
import { Back } from '@element-plus/icons';
import { setSelectedElementStyle, handleUndoText, handleUndoSticker } from '@/utils/commonFunctions';
  export default {
    components:{
        Back,
        ElDivider
    },
    data() {
      return {
        activeIndex: 'text',  // 跟踪当前激活的项
        items: [
            { text: '文字', icon: 'icon-text',route:"text" },
            { text: '贴纸', icon: 'icon-tiezhi',route:"sticker" }
        ],
        isUpdating:false,
        picture_id:null,
        // 变换状态
        transform: {
            scale_w:null,
            scale_h:null,
            x:null,
            y:null

        },
        // 背景图相对于1024 * 1024比例
        percentage: null,
        // 透明背景图
        transparent_background_url: null,
        // 是否正在拖动
        isDragging: false, 
        // 鼠标与图片的水平偏移量
        offsetX: 0,
        // 鼠标与图片的垂直偏移量
        offsetY: 0, 
        // 透明图位置
        photoPosition: {
          left: 0,
          top: 0,
        },
        // 透明图大小
        photoSize: {
          width: null,
          height: null,
        },
        // 当前缩放比例
        scale: 1,
        // 记录历史位置
        history: [],
        // 返回上次位置的下标
        undoIndex: -1,
        // 背景图theme_url
        themeUrl: null,
        // 记录分辨率，记录实际缩放比例
        assetInitData:{
            width:null,
            height:null
        },
        startX: 0, // 鼠标开始位置
        startY: 0,
        resizing: false, // 缩放状态
        anchorPoint: '', // 当前缩放的锚点
        updateStickers:[],
        updateTexts:[]
      };
    },
    mounted() {
        this.setActiveMenu(this.activeIndex)
        // 在vuex中获取要编辑的Picture
        this.getTransformData()
        // 监听键盘Ctrl + z事件，执行撤销
        window.addEventListener("keydown", this.handleUndoInEdit);
        // 根据transform的位置和缩放信息，确定照片在屏幕背景图中的位置
        this.getAssetInitData();
    },
    methods: {
        // 获取更新的Picture的位置缩放信息
        getTransformData(){
            if(Object.keys(this.$store.state.user.selectPicture).length === 0){
                this.$router.go(-1)
            }
            this.picture_id = this.$store.state.user.selectPicture.picture_id
            this.transform = JSON.parse(localStorage.getItem("selectPicture")).transform
            this.transparent_background_url = this.$store.state.user.selectPicture.transparent_background_url
            this.themeUrl = this.$store.state.user.selectPicture.theme_url
        },
        // 获取照片分辨率
        getAssetInitData() {
            // 获取照片元素
            const img = this.$refs.photo.querySelector("img");
            // 照片完成加载
            img.onload = () => {
                // 记录照片的分辨率
                this.assetInitData.width = img.naturalWidth
                this.assetInitData.height = img.naturalHeight

                // 获取themeContainer的高和宽
                const themeContainerHight = this.$refs.themeContainer.getBoundingClientRect().height;
                // 获取1024和屏幕容器比例
                const bl = 1024 / themeContainerHight;
                this.percentage = bl;
                // 将在1024 * 1024中的比例，转换为屏幕上背景图【theme_url】区域的比例
                this.transform.scale_w = this.transform.scale_w / bl
                this.transform.scale_h = this.transform.scale_h / bl

                // 获取在容器中的相对大小等比例
                this.photoPosition.left = this.transform.x / this.percentage;
                this.photoPosition.top = this.transform.y / this.percentage;
                // 获取图片的原始大小并缩放
                this.photoSize.width = img.naturalWidth * this.transform.scale_w;
                this.photoSize.height = img.naturalHeight * this.transform.scale_h;
                this.aspectRatio = this.photoSize.height / this.photoSize.width
                // 记录初始的位置缩放状态
                this.savePosition();
            };
        },
        // 保存变换记录，ctrl + 回到上次位置
        savePosition() {
            // 创建一个状态对象，保存当前图片的位置和大小
            const state = {
                // 深拷贝当前图片的位置
                position: { ...this.photoPosition },
                // 深拷贝当前图片的大小
                size: { ...this.photoSize }, 
            };
            // 将保存的状态推入历史记录数组中
            this.history.push(state);
            // 将撤销索引设置为最新的历史记录位置
            this.undoIndex = this.history.length - 1;
        },
        // 撤销商品库
        undoAssetInEdit(){
            if (this.undoIndex > 0) {
                // 撤销索引减 1，回到上一个保存的状态
                this.undoIndex--;
                // 从历史记录中获取上一个状态
                const lastState = this.history[this.undoIndex];
                // 恢复图片的位置和大小
                this.photoPosition = { ...lastState.position }; // 恢复位置
                this.photoSize = { ...lastState.size };         // 恢复大小
                // 恢复上次transform的信息
                this.transform.x = this.photoPosition.left * this.percentage
                this.transform.y = this.photoPosition.top * this.percentage
                this.transform.scale_w = this.photoSize.width / this.assetInitData.width
                this.transform.scale_h = this.photoSize.height / this.assetInitData.height
            }
        },
        // 撤销操作 ctrl + z
        handleUndoInEdit(event) {
            // 检查用户是否按下了 ctrl 键和 "z" 键
            if (event.ctrlKey && event.key.toLowerCase() === "z") {
                console.log("触发")
                const themeContainer = document.getElementById("themeContainer")
                // 获取背景容器
                if (!themeContainer){
                    return
                }
                // 获取选中的元素
                const selectedElement = themeContainer.querySelector(".selected");
                // 当元素存在的时候
                if(selectedElement){
                    // 获取元素唯一id
                    const elementUniqueId = selectedElement.id
                    // 通过id判断选中的是商品图、文本还是贴纸
                    // 如果是选中的是商品库，则进行商品图撤销
                    if(elementUniqueId == "photo"){
                        this.undoAssetInEdit()
                    }
                    // 文本撤销
                    if(elementUniqueId.startsWith("text")){
                        handleUndoText(selectedElement)
                    }
                    // 贴纸撤销
                    if(elementUniqueId.startsWith("sticker")){
                        handleUndoSticker(selectedElement)
                    }

                }
            }
            // 监听删除事件
            if (event.key === "Delete") {
                const themeContainer = document.getElementById("themeContainer");
                if(!themeContainer){
                    console.log("Not found themeContainer by themeContainerID")
                    return
                }
                // 查找被选中的文本或者贴纸
                const allElements = themeContainer.querySelectorAll(".sticker-container,.text-container");
                // 筛选出含有 selected 类的元素
                allElements.forEach(element => {
                    const deleteElement = element.querySelector(".selected")
                    if(deleteElement){
                        element.remove()
                    }
                })
            }
        },
        startDrag(event) {
            event.preventDefault();
            this.isDragging = true;
            this.offsetX = event.clientX - this.photoPosition.left;
            this.offsetY = event.clientY - this.photoPosition.top;
            document.addEventListener("mousemove", this.onDrag);
            document.addEventListener("mouseup", this.endDrag);
        },
        // 拖动
        onDrag(event) {
            if (this.isDragging) {
                let newLeft = event.clientX - this.offsetX;
                let newTop = event.clientY - this.offsetY;
        
                // 边界检查，防止超出容器
                const themeContainerWidth = this.$refs.themeContainer.offsetWidth;
                const themeContainerHeight = this.$refs.themeContainer.offsetHeight;
                const photoWidth = this.photoSize.width;
                const photoHeight = this.photoSize.height;
                // 确保不会超出边界
                newLeft = Math.max(0, Math.min(themeContainerWidth - photoWidth, newLeft));
                newTop = Math.max(0, Math.min(themeContainerHeight - photoHeight, newTop));
                // 记录此次相对于1024 * 1024的位置
                this.transform.x = this.percentage * newLeft
                this.transform.y = this.percentage * newTop
                // 修改图片位置
                this.photoPosition = { left: newLeft, top: newTop };
            }
        },
        // 停止拖动
        endDrag() {
            if (this.isDragging) {
                // 结束拖动
                this.isDragging = false;
                // 拖动结束后保存位置
                this.savePosition();
                document.removeEventListener("mousemove", this.onDrag);
                document.removeEventListener("mouseup", this.endDrag);
            }
        },
        // 返回
        cancelUpdatePicture(){
            this.$router.push("/picturepreview")
        },
        updatePictue(){
            this.getAssetTransform()
            this.getUpdateStickers()
            this.getUpdateTextStyle()
            this.isUpdating = true
            // TODO:显示全屏加载中会影响Asset的位置
            const loading = ElLoading.service({
                lock: true,
                text: '正在更新中...',
                background: 'rgba(0, 0, 0, 0.7)',
            });
            const timeout = 120000;
            const updateTimeoutId = setTimeout(() => {
                loading.close();
                ElMessage({
                    message:"请求超时请重试",
                    type:"info",
                })
            }, timeout);
            updataPicture(
                this.picture_id,
                {
                    transform:this.transform,
                    texts:this.updateTexts,
                    stickers:this.updateStickers
            }).then(response => {
                if(response.status == HttpCodes.SUCCESS){
                    this.updateStickers = []
                    this.updateTexts = []
                    this.isUpdating = false
                    clearTimeout(updateTimeoutId)
                    loading.close()
                    // 还原到屏幕背景中 800 * 800
                    this.transform.scale_w = this.transform.scale_w / this.percentage
                    this.transform.scale_h = this.transform.scale_h / this.percentage
                    // 更新预览模式下的picture的url
                    const selectPicture = this.$store.state.user.selectPicture
                    selectPicture.url = response.data.data.new_url
                    this.$store.commit("setSelectPicture", selectPicture)
                    ElMessage({
                        message:"更新成功",
                        type:"success"
                    })
                }
            }).catch(error => {
                console.log("Update picture error:",error)
                this.updateStickers = []
                this.updateTexts = []
                // 还原到屏幕背景中 800 * 800
                this.transform.scale_w = this.transform.scale_w / this.percentage
                this.transform.scale_h = this.transform.scale_h / this.percentage
                this.isUpdating = false
                loading.close()
                clearTimeout(updateTimeoutId)
            })
        },
        // 撤销编辑回到最初位置
        undoEditPicture(){
            // 获取第一个初始位置的值
            const lastState = this.history[0];
            // 改变位置
            this.photoPosition = { ...lastState.position }; // 恢复位置
            // 改变大小
            this.photoSize = { ...lastState.size };  
        },
        selectAssetClick(element_id){
            setSelectedElementStyle(element_id,"themeContainer")
        },
        startResize(event, point) {
            event.stopPropagation()
            // 记录鼠标初始位置和锚点
            this.startX = event.clientX;
            this.startY = event.clientY;
            this.anchorPoint = point;
            this.resizing = true;

            // 监听鼠标移动和松开事件
            document.addEventListener("mousemove", this.onResize);
            document.addEventListener("mouseup", this.stopResize);
        },
        // 缩放过程
        onResize(event) {
            event.stopPropagation()
            if (!this.resizing) return;
            // 计算鼠标移动距离
            const dx = event.clientX - this.startX;
            let themeContainerHight = this.$refs.themeContainer.getBoundingClientRect().height;
            let themeContainerWidth = this.$refs.themeContainer.getBoundingClientRect().width;
            // 根据锚点更新位置和尺寸
            switch (this.anchorPoint) {
                case "top-left":
                    if(dx < 0 && (this.photoSize.width >= themeContainerWidth || this.photoSize.height >= themeContainerHight)){
                        return
                    }
                    this.photoSize.width -= dx;
                    this.photoSize.height = this.photoSize.width * this.aspectRatio;
                    this.photoPosition.left += dx;
                    this.photoPosition.top += dx * this.aspectRatio;
                    break;
                case "top-right":
                    if(dx > 0 && (this.photoSize.width >= themeContainerWidth || this.photoSize.height >= themeContainerHight)){
                        return
                    }
                    this.photoSize.width += dx;
                    this.photoSize.height = this.photoSize.width * this.aspectRatio;
                    this.photoPosition.top -= dx * this.aspectRatio;
                    break;
                case "bottom-left":
                    if(dx < 0 && (this.photoSize.width >= themeContainerWidth || this.photoSize.height >= themeContainerHight)){
                        return
                    }
                    this.photoSize.width -= dx;
                    this.photoSize.height = this.photoSize.width * this.aspectRatio;
                    this.photoPosition.left += dx;
                    break;
                case "bottom-right":
                    if(dx > 0 && (this.photoSize.width >= themeContainerWidth || this.photoSize.height >= themeContainerHight)){
                        return
                    }
                    this.photoSize.width += dx;
                    this.photoSize.height = this.photoSize.width * this.aspectRatio;
                    break
            }
            // 更新初始鼠标位置
            this.startX = event.clientX;
            this.startY = event.clientY;
            // 记录此次的位置信息
            this.savePosition()
        },
        // 停止缩放
        stopResize() {
            this.resizing = false;
            document.removeEventListener("mousemove", this.onResize);
            document.removeEventListener("mouseup", this.stopResize);
        },
        // 设置激活哪一个菜单按钮
        setActiveMenu(index) {
            console.log(this.$route.path)
            this.activeIndex = index;
            if(this.activeIndex == "text"){
                this.$router.push("/editpicture/text")
            }
            if(this.activeIndex == "sticker"){
                this.$router.push("/editpicture/sticker")
            }
        },
        getUpdateTextStyle(){
            const themeContainer = document.getElementById('themeContainer');
            const pElements = themeContainer.querySelectorAll('.text-container');
            const percentage = 1024 / themeContainer.getBoundingClientRect().height
            pElements.forEach(element => {
                const pTag = element.querySelector('p');
                const textContainer = element.querySelector('.DT-text-container');
                const textStyle = {
                    // 字体样式
                    font_family: textContainer.style.fontFamily || "",
                    // 字体粗细
                    font_weight: textContainer.style.fontWeight || "",
                    // 排列方式【左对齐、右对齐、居中、两端对齐】
                    text_align: textContainer.style.textAlign || "",
                    // 是否有下划线或者删除线
                    text_decoration: textContainer.style.textDecoration || "",
                    // 字体大小
                    font_size: parseInt(textContainer.style.fontSize, 10) || 0,
                    // 行间距
                    line_height: parseInt(textContainer.style.lineHeight, 10) || 0,
                    // 字间距
                    letter_spacing: parseFloat(textContainer.style.letterSpacing) || 0,
                    // 颜色
                    color: textContainer.style.color || "",
                    // 背景色
                    background_color: textContainer.style.backgroundColor || "",
                    // 去除单位 'px'，并确保值为空时设置为 0
                    x: element.style.left ? parseFloat(element.style.left.replace("px", "")) : 0, // x轴距离
                    y: element.style.top ? parseFloat(element.style.top.replace("px", "")) : 0 ,// y轴距离
                    // 文本框的宽度和高度
                    width:parseFloat(element.getBoundingClientRect().width),
                    height:parseFloat(element.getBoundingClientRect().height),
                };
                // 传换为1024 * 1024比例
                textStyle.letter_spacing = (textStyle.letter_spacing * percentage).toFixed(1);
                textStyle.font_size = textStyle.font_size * percentage
                textStyle.x = textStyle.x * percentage
                textStyle.y = textStyle.y * percentage
                textStyle.width = textStyle.width * percentage
                textStyle.height = textStyle.height * percentage
                textStyle.text_content = pTag.textContent
                this.updateTexts.push(textStyle)
            })
        },
        // 获取generate的贴纸
        getUpdateStickers(){
            // 获取透明背景容器
            const themeContainer = document.getElementById('themeContainer');
            // 查询里面的所有贴纸容器
            const stickers = themeContainer.querySelectorAll('.sticker-container');
            // 遍历
            stickers.forEach(stickerContainer =>{
                const sticker = stickerContainer.querySelector("img")
                // 获取贴纸的分辨率实际大小
                const stcikerNaturalWidth = sticker.naturalWidth
                const stickerWidth = sticker.width
                // 计算出缩放比例 【等比缩放】
                const scale = stickerWidth / stcikerNaturalWidth * this.percentage
                // 祖闯贴纸信息
                const stickerInformations = {
                    url:sticker.src,
                    scale_w:scale,
                    scale_h:scale,
                    // x轴距离
                    // 如果stickerContainer的left为空字符串则没尽兴移动，位置为0，否则就取出left的值去掉单位 px
                    x: stickerContainer.style.left === "" ? 0 : parseFloat(stickerContainer.style.left.replace("px", "")),
                    // y轴距离
                    y: stickerContainer.style.top === "" ? 0 : parseFloat(stickerContainer.style.top.replace("px", ""))
                }
                stickerInformations.x = stickerInformations.x * this.percentage
                stickerInformations.y = stickerInformations.y * this.percentage
                this.updateStickers.push(stickerInformations)
            })
        },
        // 获取asset变换状态
        getAssetTransform(){
            const asset = document.getElementById("photo")
            this.transform.x = parseInt(asset.style.left) * this.percentage
            this.transform.y = parseInt(asset.style.top) * this.percentage
            const assetImg = asset.querySelector("img")
            this.transform.scale_w = this.photoSize.width / assetImg.naturalWidth * this.percentage
            this.transform.scale_h = this.transform.scale_w 
        },
    },
    // 组件卸载移除keydowm事件
    beforeUnmount() {
        document.removeEventListener("keydown",this.handleUndoInEdit)
    },
};
</script>
  
<style scoped>
.edit-page-container{
    display: flex;
    user-select: none;
    width: 100%;
    height: 100%;
    justify-content: space-between;
}

.edit-main-container{
    flex: 3.5;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafbfc;
}


#themeContainer {
    width: 90%;
    aspect-ratio: 1 / 1;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
  
#photo {
    cursor: grab;
    position: relative;
    border: 2px dashed transparent;
}
#photo.selected{
    border-color:rgb(191, 117, 33);
}

#photo.selected .dian{
    display: block;
}
#photo:hover{
    border-color:rgb(191, 117, 33);
}
#photo:hover .dian{
    display: block;
}
.dian {
    display: none;
    width: 10px; /* 圆点的大小 */
    height: 10px;
    background-color:  rgb(191, 117, 33); /* 圆点颜色 */
    border-radius: 50%; /* 使 div 变成圆形 */
    position: absolute;
}

.top-left { top: -5px; left: -5px; cursor: nwse-resize; }

.top-right { top: -5px;right: -5px; cursor: ne-resize; }

.bottom-left { bottom: -5px; left: -5px;cursor: nesw-resize; }

.bottom-right { bottom: -5px; right: -5px;cursor: nwse-resize; } 

.dian:hover {
    transform: scale(1.5);
}


.btn-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start; 
    align-items: flex-end; 
}
.btn-container div:hover{
    cursor: pointer;
}
.btn-container div span{
    padding: 15px 40px;
    background-color: #409eff;
    color: white;
    border-radius: 10px;
    margin-left: 15px;
    white-space: nowrap;
}
.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.edit-page-menu-container{
    flex: 1.5;
    display: flex;
}
.edit-navigator{
    width: 17%;
    height: 100%;

}

.navigator-item {
    display: flex;
    width: 100%;
    aspect-ratio: 1 / 1;
    flex-direction: column;
    padding: 10px 0px;
    box-sizing: border-box;
}
.active-color{
    color: #409eff;
}
.navigator-item div{
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    border-left: 3px solid transparent;
    padding: 5px 0px;
}
.navigator-item div:hover{
    border-left: 3px solid #409eff; 
}
.navigator-item div.active {
    border-left: 3px solid #409eff;  /* 激活项的左侧蓝条 */
}

  </style>
  