<!-- Copyright © 2023-2024 深圳市鹏然信息技术有限公司. All Rights Reserved -->
<template>
  <div style="display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;">
      <div class="wx_login_box" v-if="show_success">
          <div>
            <!-- 倒计时 -->
            <div v-if="showCountDown">
              <p>二维码将在 <span style="color: blue;">{{ minutes }}:{{ seconds }}</span> 后失效，请及时扫描登录！</p>
            </div>
            <p>使用微信扫码完成登录</p>
            <!-- 二维码 -->
            <img :src="code_url" alt="">
          </div>
          <!-- 返回按钮 -->
          <div>
            <ElButton type="info" @click="goBack" plain size="large">返回</ElButton>
          </div>
      </div>
      <!-- 登陆成功 -->
      <div v-if="!show_success">
          <el-result
            icon="success"
            title="登录成功"
          >
          </el-result>
      </div>
  </div>
  
</template>
<script>
import { getWxCodeUrl,wxloginPoll } from '@/api';
import { HttpCodes } from '@/common/const';
import { ElResult,ElButton } from 'element-plus';
export default {
  components:{
    ElResult,
    ElButton
  },
  name: "PayPage",
  data(){
      return{
          code_url:"",
          show_success:true,
          scene_str:"",
          timeout: 600, // 十分钟六百秒
          interval: null,
          showCountDown:false
      }
  },
  mounted(){
    this.getQRCode()
  },
  methods: {
      goBack(){
        clearInterval(this.interval)
        clearInterval(this.tem_poll)
        this.$router.push("/home")
      },
      finish(){
          this.show_success = false
      },
      // 获取登陆二维码
      getQRCode(){
        getWxCodeUrl().then(response =>{
          this.code_url = response.data.data.qr_code_url
          this.showCountDown = true
          this.startCountdown()
          this.scene_str = response.data.data.scene_str
          // 开启前端轮询定时器
          this.tem_poll = setInterval(this.loginPoll, 1000)
        }).catch(error => {
          console.log(error)
        })
      },
      
      loginPoll() {
        wxloginPoll({
          scene_str:this.scene_str
        }).then(response => {
          if(response.status == HttpCodes.SUCCESS){
            // 清除定时器
            clearInterval(this.tem_poll)
            clearInterval(this.interval)
            const { drawsettings, ...userInfo } = response.data;
            const removedKeyValuePair = { drawsettings };
            const now = Date.now();
            // 超时时间为refresh_token的有效时间，后端refresh_token配置的三天之内有效
            const millisecondsInThreeDays = 3 * 24 * 60 * 60 * 1000;
            const timestampThreeDaysLater = now + millisecondsInThreeDays;
            userInfo.timeout = timestampThreeDaysLater
            localStorage.setItem("userInfo",JSON.stringify(userInfo))
            localStorage.setItem("drawsettings",JSON.stringify(removedKeyValuePair.drawsettings))
            this.$router.push("/personal").then(() => {
                window.location.reload();
            });
          }
        }).catch(error =>{
          console.log("Wxlogon error:",error)
        })
      },
      startCountdown() {
      this.interval = setInterval(() => {
        if (this.timeout > 0) {
          this.timeout--;
        } else {
          clearInterval(this.interval);
          this.timeout = 600
          // 刷新二维码
          this.getQRCode()
        }
      }, 1000);
    },
  },
  computed:{
    minutes() {
      return Math.floor(this.timeout / 60);
    },
    seconds() {
      return this.timeout % 60 < 10 ? '0' + this.timeout % 60 : this.timeout % 60;
    },
  }
}

</script>

<style>
.wx_login_box{
  margin: 0 auto;
}
.wx_login_box img {
  display: inline-block;
  width: 300px;
  height: auto;
}
.el-result{
  --el-result-icon-font-size:150px !important;
  --el-result-title-font-size:30px !important
}
.el-result__title p{
  font-weight: bolder !important;
}
</style>