// src/common/statusCodes.js
export const HttpCodes = Object.freeze({

    // 请求成功
    SUCCESS: 200,
    // 已成功创建
    CREATED: 201,
    // 删除成功
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
  });

  
export const StatusCodes = Object.freeze({
  SMS_CODE_THROTTLING_ERR: 4009,
  PRODUCT_EXCEED_PICTURES_MAXIMUM_ERR:7027
})

// 订单描述
export const ORDER_DESCRIPTION = {
  MONTHLY: 'Basic会员月费充值',
  SEASON: 'Basic会员季费充值',
  YEARLY: 'Basic会员年费充值',
  MONTHLY_PRO: 'Pro会员月费充值',
  SEASON_PRO: 'Pro会员季费充值',
  YEARLY_PRO: 'Pro会员年费充值'
};


export const PricingCodes = Object.freeze({
  MEMBERSHIP_FREE: "FREE",
  IDENTITY_STATUS_NON_MEMBER: "非会员"
});


// 用户登录注册错误相关
export const ErrorMap = Object.freeze({
  "User does not exist.":"用户不存在，请先完成注册！",
  "The password is incorrect.":"密码错误，请确认密码是否正确！",
  "The verification code is incorrect.":"验证码错误，请确认验证码是否正确！",
  "The user already exists.":"该用户已存在，请进行登录！",
  "This phone number is already registered.":"该手机号已被注册，请进行登录",
  "Old password is incorrect!":"旧密码不正确！"
})

  