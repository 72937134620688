<template>
    <div style="position: relative;">
        <div class="colorBtn" @click="openColorSelect">
            <div :style="{backgroundColor: localColor}"></div>
            <!-- <el-icon style="height: 15px;width: 15px;" v-else><Close /></el-icon> -->
        </div>
        <!-- 使用 Vue 的 transition 组件包裹 div，增加过渡动画 -->
        <transition @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div class="colorContainer"  v-if="showColorSelect" :style="{ left: left,width: width }">
            <div class="colors">
              <!-- 标准颜色 -->
              <div class="bColor">
                  <div 
                      v-for="color in bColor" 
                      :key="color" 
                      :style="{ backgroundColor: color }" 
                      class="colorItem"
                      @click="selectColorCilck(color,true)"
                  ></div>
              </div>
              <ElDivider style="margin: 5px 0px"></ElDivider>
              <!-- 纯色 -->
              <div class="CColor">
                  <div 
                      v-for="color in pureColorList" 
                      :key="color" 
                      :style="{ backgroundColor: color }" 
                      class="colorItem"
                      @click="selectColorCilck(color,true)"
                  ></div>
              </div>
              <!--颜色  -->
              <div class="colorConfig">
                  <div 
                      v-for="(colorList, index) in extendedColorConfig" 
                      :key="index" 
                      class="colorList">
                      <div 
                          v-for="color in colorList.slice().reverse()" 
                          :key="color" 
                          :style="{ backgroundColor: color, marginBottom:'5px' }"
                          class="colorItem"
                          @click="selectColorCilck(color,true)"
                          >
                      </div>
                  </div>
              </div>
              <!-- 分界线 -->
              <div class="FJX"></div>
              <!-- 最近使用 -->
              <div class="recently">
                  <span>最近使用</span>
                  <div class="recentlyColor" style="margin-top: 0;">
                  <div 
                      v-for="color in recentlyColorList.slice().reverse().slice(0, 20)"
                      :key="color" 
                      :style="{ backgroundColor: color }" 
                      class="colorItem"
                      @click="selectColorCilck(color,false)"
                  ></div>
              </div>
              </div>
                  <!-- 分界线 -->
              <div class="FJX"></div>
              <!-- 更多颜色 -->
              <div class="moreColors" @click="openDetailColors">
                  <span >更多颜色</span>
                  <el-icon style="font-size: larger;font-weight: bolder;margin-top: 5px;height: 20px;width: 20px;"><ArrowRightBold /></el-icon>
              </div>
            </div>
            <div class="detailedColor" v-if="showDetailSelect" @click="stopOtherEvent($event)">
              <DetailColorPicker :selectedColor=selectedColor  @color-changed="onDetailColorChanged"/>
            </div>
          </div>
        </transition>
    </div>
  </template>
  
  <script>
  import DetailColorPicker from './DetailColorPicker.vue';
  import { ArrowRightBold } from '@element-plus/icons';
  import { ElDivider } from 'element-plus';
  export default {
    components:{
      ArrowRightBold,
      DetailColorPicker,
      ElDivider

    },
    name: 'ColorPicker',
    props:{
      selectedColor: {
        type: String,
        default: 'rgba(255, 0, 0, 0)' 
      },
      left:{
        type: String,
        default: '-270px' 
      },
      width:{
        type:String,
        default:"17vw"
      },
    },
    data() {
        return {
            localColor: this.selectedColor,
            bColor: ['#c21401', '#ff1e02', '#ffc12a', '#ffff3a', '#90cf5b', '#00af57', '#00afee', '#0071be', '#00215f', '#72349d'],
            pureColorList:['#FFFFFF','#000000','#EEECE1','#1F497D','#4F80BD','#CD5C5C','#006400','#E6E6FA','#3D6B8C','#F79646'],
            colorConfig: [
                ['#7f7f7f', '#f2f2f2'],
                ['#0d0d0d', '#808080'],
                ['#1c1a10', '#ddd8c3'],
                ['#0e243d', '#c6d9f0'],
                ['#233f5e', '#dae5f0'],
                ['#632623', '#f2dbdb'],
                ['#4d602c', '#eaf1de'],
                ['#3f3150', '#e6e0ec'],
                ['#1e5867', '#d9eef3'],
                ['#99490f', '#fee9da']
            ],
            showColorSelect:false,
            showDetailSelect:false,
            // 最近使用颜色
            recentlyColorList:[],
        };
    },
    computed: {
        // 扩展颜色
        extendedColorConfig() {
            return this.colorConfig.map(pair => this.generateGradientColors(pair[0], pair[1]));
        }
    },
    methods: {
        isTransparent(color) {
          // 判断颜色是否为透明色
          if (color.startsWith('rgba')) {
            const alpha = parseFloat(color.split(',')[3]); // 获取透明度
            return alpha === 0;
          }
          if (color.startsWith('#')) {
            // 如果是 #RRGGBBAA 或 #RGBA 格式
            if (color.length === 9) return color.slice(7, 9).toUpperCase() === '00';
            if (color.length === 5) return color.slice(4, 5).toUpperCase() === '0';
          }
          return color.toLowerCase() === 'transparent';
        },
        getBorderColor(color) {
          return this.isTransparent(color) ? 'black' : color;
        },
        // 生成两个颜色之间的渐变色
        generateGradientColors(color1, color2) {
            const colors = [color1];
            
            // 将颜色转换为 RGB 数值
            const rgb1 = this.hexToRgb(color1);
            const rgb2 = this.hexToRgb(color2);
    
            // 生成 4 个中间的颜色
            for (let i = 1; i <= 4; i++) {
                const r = Math.round(rgb1.r + (rgb2.r - rgb1.r) * (i / 5));
                const g = Math.round(rgb1.g + (rgb2.g - rgb1.g) * (i / 5));
                const b = Math.round(rgb1.b + (rgb2.b - rgb1.b) * (i / 5));
                colors.push(this.rgbToHex(r, g, b));
            }
    
            colors.push(color2); // 添加最深色（终止色）
    
            // 返回颜色数组，从浅到深排列
            return colors;
        },
        // 颜色选择事件
        // flag:用来判断用户在哪里点击改变颜色，如果在最近颜色里面改变，则不改变最近颜色列表，否则进行改变
        selectColorCilck(color,flag) {
          event.stopPropagation(); // 阻止事件冒泡
          this.localColor = color
          // 改变文本的颜色
          this.$emit('update:selectedColor', color);  // 触发事件，将颜色传递给父组件
          if(flag){
            this.setRecentlyColor(color)
          }
        },
        // 设置最近使用颜色列表
        setRecentlyColor(color){
          // 该颜色不存在则直接添加为最近颜色
          if (!this.recentlyColorList.includes(color)) {
              this.recentlyColorList.push(color);
              // 该颜色存在则移除，重新添加改颜色
            }else{
              // 获取这个已有颜色在数组中的位置
              const indexOfColor = this.recentlyColorList.indexOf(color)
              // 移除改颜色
              this.recentlyColorList.splice(indexOfColor, 1);
              this.recentlyColorList.push(color);
            }
          // 最多保留20个最近使用的颜色
          // 如果 recentlyColorList 的长度小于等于 20，则保存全部
          // 如果长度大于 20，则保存最后 20 个元素
          this.$store.commit("setRecentlyColorList", this.recentlyColorList.slice(-20));
        },
        // 将十六进制颜色转换为 RGB 对象
        hexToRgb(hex) {
            const match = hex.match(/^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})$/i);
            return match
            ? {
                r: parseInt(match[1], 16),
                g: parseInt(match[2], 16),
                b: parseInt(match[3], 16)
                }
            : { r: 0, g: 0, b: 0 }; // 如果格式不正确，返回默认值
        },
        // 将 RGB 对象转换为十六进制颜色
        rgbToHex(r, g, b) {
            return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1).toUpperCase()}`;
        },
        // 展开颜色选择
        openColorSelect(){
          event.stopPropagation(); // 阻止事件冒泡
          this.showColorSelect = !this.showColorSelect
        },
        // 打开详情选择
        openDetailColors(){
          event.stopPropagation(); // 阻止事件冒泡
          this.showDetailSelect = !this.showDetailSelect
        },
        // 进入前的状态
        beforeEnter(el) {
          el.style.opacity = 0; // 初始状态为透明
          el.style.transform = "scale(0.8)"; // 初始状态为缩小
        },
        // 进入时的过渡
        enter(el, done) {
          el.offsetHeight; // 触发重新渲染，避免动画被跳过
          el.style.transition = "opacity 0.3s, transform 0.3s"; // 设置过渡
          el.style.opacity = 1; // 显示时透明度为 1
          el.style.transform = "scale(1)"; // 放大到正常大小
          done();
        },
        // 离开时的过渡
        leave(el, done) {
          el.style.transition = "opacity 0.3s, transform 0.3s"; // 设置过渡
          el.style.opacity = 0; // 隐藏时透明度为 0
          el.style.transform = "scale(0.8)"; // 缩小
          done();
        },
        // 监听详情颜色选择器
        onDetailColorChanged(newColor){
          this.localColor = newColor
          this.$emit('update:selectedColor', newColor);
          this.setRecentlyColor(newColor)
        },
        // 点击颜色选择器以外的位置关闭颜色选择器
        handleClickOutside(event) {
          event.stopPropagation()
          this.showColorSelect = false
          this.showDetailSelect = false
        },
        stopOtherEvent(e){
          e.stopPropagation()
        }

    },
    mounted(){
      document.addEventListener('click', this.handleClickOutside);
      this.recentlyColorList = this.$store.state.user.recentlyColorList
    },
    beforeUnmount() {
      // 移除点击事件监听器
      document.removeEventListener('click', this.handleClickOutside);
    }

  };
  </script>
  
  <style scoped>

  .colorContainer{

    padding-right: 20px;
    display: flex;
    align-items: flex-end;
    position: absolute;
    z-index: 9999;
    top: -410px;
    width: 17.5vw;
    bottom: 5vh;
  }
  .colors{
    justify-content: center;
    align-items: center;
    user-select: none;
    padding-left: 4%;
    padding-right: 6%;
    background: white;
  }



  .colorBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d2cfce;
    width: 35px;
    height: 35px;
    cursor: pointer;

  }
  .colorBtn div {
    background-color: black;
    width: 25px;
    height: 25px;
  }
  
  .bColor {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 5px;
    width: 100%;
  }
  .CColor {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 5px;
    width: 100%;
  }
  .CColor div{
    border: 1px solid  #dcdee0;
  }
  .colorItem{
    width: 25px;
    height: 25px;
    transition: transform 0.3s ease; /* Smooth scale effect */
    border-radius: 5px;
  }
  .colorItem:hover{
    transform: scale(1.2);
  }
  
  
  .colorConfig {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 5px;
  }
  
  .colorList {
    display: flex;
    flex-direction: column;
  }
  .colorList div{
    border: 1px solid  #dcdee0;
  }
  .recently{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .recentlyColor{
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 5px;
    width: 100%;
  }
  .recentlyColor div{
    border: 1px solid  #dcdee0;
  }
  .recently span{
    margin-left: 5px;
    color: rgb(130, 127, 127);
    margin-bottom: 5px;
  }
  .FJX{
    background-color: rgb(209, 205, 205);
    height: 2px;
    margin: 5px 0px;
  }
  .moreColors{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background: rgb(239, 236, 236);
    height: 50px;
    padding: 0px 10px;
  } 
  
  .moreColors i{
    font-weight: bolder;
    font-size: larger;
  }

  .moreColors span{
    margin-left: 5px;
    font-weight: bold;
  }
  .detailedColor{
    margin-right: 10px;
    background-color: white;

  }
  </style>
  